/* eslint-disable-next-line no-shadow */
export enum ISyncActionType {
    None,
    Pointer,
    ChangePointage,
    Depointer,
    FicheExposition,
    PushRegistration,
    PointageV2,
    ChangePointageV2,
    ChangeGrDep,
    ChangeHotelName,
    AddDayFile,
}

export interface ISyncAction {
    actionType: ISyncActionType;
    at: Date;
    payload: any;
}

export interface ISyncDbAction extends ISyncAction {
    order: number;
}

export interface ISyncActionResult {
    status: string;
    code: string | undefined;
}
