import { ClockInEventData, IEvenementPointageData } from '../evenement-pointage/evenement-pointage.types';

export const MIN_KILOMETERS_GRDEP = 50;

/* eslint-disable-next-line no-shadow */
export enum PointageDetectionMode {
    Automatique,
    Manuel
}

/* eslint-disable-next-line no-shadow */
export enum PointageTransportMode {
    NULL,
    DoesNotCount,
    ByMyself,
    Company
}

/* eslint-disable-next-line no-shadow */
export enum PointageStatus {
    NULL,
    Valid,
    LocationAnomaly,
    TimeAnomaly,
    DeviceAnomaly,
    DurationAnomaly,
    ChantierAnomaly,
    Started,
    Ended
}

/* eslint-disable-next-line no-shadow */
export enum ClockInDayPayedCompensationType {
    NULL = 0,
    ZA = 1 << 0,
    ZR = 1 << 1,
    ZAR = ZA | ZR,
    GRDEP = 1 << 2
}

/* eslint-disable-next-line no-shadow */
export enum TravelDirection {
    Way1,
    WayHome
}

/* eslint-disable-next-line no-shadow */
export enum TravelStatus {
    Calculated,
    SourceError,
    DestinationError,
    DistanceError
}

export interface IPointageData {
    id: string;
    activityMatched: boolean;
    chantierId: string | null;
    codeChantier: string | null;
    description: string | null;
    hotelName: string | null;
    isGd: boolean | null;
    isWorkingRemotely: boolean;
    askForGd: boolean | null;
    kmParcourus: number;
    kmParcourusBackup: number;
    kmParcourusRetour: number;
    modeDetectionChantier: PointageDetectionMode;
    modeTransport: PointageTransportMode;
    name: string;
    receipt?: string;
    status: PointageStatus;
    userId: string;
    version: number;
}

export type IPointage = IPointageData;

export interface UserPointageViewModel {
    pointage: IPointageData;
    startData: IEvenementPointageData;
    stopData?: IEvenementPointageData;
}

export type IClockInData = {
    id: string;
    activityCode?: string;
    activityId?: string;
    activityMatched: boolean;
    detectionMode: PointageDetectionMode;
    askForGd: boolean | null;
    hotelName?: string;
    isGd: boolean | null;
    isWorkingRemotely: boolean;
    kmParcourus: number;
    kmParcourusRetour: number;
    transportMode: PointageTransportMode;
    userId: string;
    name: string;
    description?: string;
    status: PointageStatus;
    startTime?: string | Date;
    stopTime?: string | Date;
    startInfo?: ClockInEventData;
    stopInfo?: ClockInEventData;
    version: number;
}

export type ITravelData = {
    no: number;
    at: string | Date;
    fromActivityName: string;
    toActivityName: string;
    kilometers: number;
    transportMode: PointageTransportMode;
    direction: TravelDirection;
    clockInId: string;
    status: TravelStatus;
}

export type IClockInDayData = {
    id: string;
    day: string | Date;
    hotelName?: string;
    userId: string;
    payedCompensationTypeValue: ClockInDayPayedCompensationType;
    travels?: Array<ITravelData>;
    sumOfKilometersWayOne: number;
    additionnalKilometersWayOne: number;
    kilometersToHome: number;
    additionnalKilometersToHome: number;
    temporaryKilometersToHome: number;
    askForGrDep: boolean;
}

export type ClockInDayViewModel = {
    events: Array<any>;
    isGrDep: boolean;
    totalFoodTicket: number;
    totalZA: number;
    totalZR: number;
}