























































































import {Vue, Component} from 'vue-property-decorator';
import colors from 'vuetify/lib/util/colors';
import SvgIcon from '@/components/SvgIcon.vue';
import {ExpositionModule} from '@/store/modules/exposition';

@Component({
    components: {SvgIcon}
})
export default class Step2Component extends Vue {
    private temperature: number | null = null;
    private empoussierement: number | null = null;
    private colors = colors;

    private showConfirmDialog = false;

    private get showValidate() {
        return ExpositionModule.ficheEnCours?.etape === 2;
    }

    public validate(force: boolean) {
        if (
            (this.temperature === null ||
                this.empoussierement === null ||
                (this.temperature as unknown) === '' ||
                (this.empoussierement as unknown) === '') &&
            !force
        ) {
            this.showConfirmDialog = true;
            return;
        }
        this.showConfirmDialog = false;
        this.$emit('validate', {
            temperature: this.temperature,
            empoussierement: this.empoussierement
        });
    }
}
