




































































































import {Vue, Component, Prop} from 'vue-property-decorator';
import VueCountdown from '@chenfengyuan/vue-countdown';
import SvgIcon from '@/components/SvgIcon.vue';
import {Howl} from 'howler';
import NoSleep from 'nosleep.js';
import {padNumber as PADNUMBER} from '@/utils';
import {ExpositionModule} from '@/store/modules/exposition';

@Component({
    components: {VueCountdown, SvgIcon}
})
export default class Step3Component extends Vue {
    @Prop({type: Boolean, required: true})
    private limiteDansLeTemps!: boolean;

    private noSleep: NoSleep = new NoSleep();
    private displayInZoneCountdown = false;

    private now = new Date();

    private padNumber = PADNUMBER;

    private mounted() {
        this.$nextTick(() => {
            this.noSleep.enable();
            this.displayInZoneCountdown = this.tempsAvantZone === 0;
            this.now = new Date();
        });
    }

    private destroyed() {
        this.$nextTick(() => {
            this.noSleep.disable();
        });
    }

    private alertSound!: Howl | undefined;

    private beforeEnterProgress(data: any) {
        this.now = new Date();
        if (data.totalSeconds < 10) {
            if (this.alertSound === undefined) {
                this.alertSound = new Howl({
                    // eslint-disable-next-line global-require
                    src: [require('@/assets/sounds/Beep_Short.mp3')]
                });
            }
            this.alertSound.play();
        }
    }

    private beforeEnterEnd() {
        this.now = new Date();
        if (this.alertSound !== undefined) {
            this.alertSound.stop();
            this.alertSound = undefined;
        }
        this.displayInZoneCountdown = true;
    }

    private inZoneProgress(data: any) {
        this.now = new Date();
        if (!this.limiteDansLeTemps) {
            return;
        }
        if (data.totalSeconds <= 120 && data.totalSeconds > 100) {
            if (this.alertSound === undefined) {
                this.alertSound = new Howl({
                    // eslint-disable-next-line global-require
                    src: [require('@/assets/sounds/Beep_Short.mp3')]
                });
            }
            this.alertSound.play();
        } else if (data.totalSeconds <= 100 && this.alertSound !== undefined) {
            this.alertSound.stop();
            this.alertSound = undefined;
        }
    }

    private inZoneEnd() {
        this.now = new Date();
        if (!this.limiteDansLeTemps) {
            return;
        }
        if (this.alertSound !== undefined) {
            this.alertSound.stop();
            this.alertSound = undefined;
        }
    }

    private endZoneProgress(data: any) {
        this.now = new Date();
        if (!this.limiteDansLeTemps || this.tempsSortieZone > 0) {
            return;
        }
        if (data.totalSeconds <= 30) {
            if (this.alertSound === undefined) {
                this.alertSound = new Howl({
                    // eslint-disable-next-line global-require
                    src: [require('@/assets/sounds/Beep_Short.mp3')]
                });
            }
            this.alertSound.play();
        }
    }

    private endZoneEnd() {
        this.now = new Date();
        if (!this.limiteDansLeTemps) {
            return;
        }
        if (this.alertSound !== undefined) {
            this.alertSound.stop();
            this.alertSound = undefined;
        }
    }

    private handleClickExitZone() {
        this.$emit('exitzone');
    }

    private get tempsAvantZone() {
        const tempsSec = ExpositionModule.ficheEnCours?.getTempsAvantZoneEnSeconde(this.now) || 0;
        if (tempsSec > 0) {
            return tempsSec;
        }
        return 0;
    }

    private get tempsEnZone() {
        const tempsSec =
            ExpositionModule.ficheEnCours?.getTempsRestantEnZoneEnSeconde(this.now) || 0;
        if (tempsSec > 0) {
            return tempsSec;
        }
        return 0;
    }

    private get tempsSortieZone() {
        const tempsSec = ExpositionModule.ficheEnCours?.getTempsAvantSortieEnSeconde(this.now) || 0;
        if (tempsSec > 0) {
            // 7 minutes pour sortir de zone
            return tempsSec;
        }
        return 0;
    }
}
