import {IClockInData, PointageDetectionMode} from '@/entities/pointage/pointage.types';
import {ChantierModule} from '@/store/modules/chantier';

export function detectChantier(
    clockin: IClockInData
): IClockInData {
    const nClockIn: IClockInData = {
        ...clockin,
        activityMatched: false,
        activityId: undefined
    };

    if (clockin.detectionMode === PointageDetectionMode.Automatique) {
        let {chantiers} = ChantierModule;
        const count = chantiers.length;
        if (count > 0) {
            chantiers = chantiers.sort((a, b) => ((b.lastModifiedDate as Date)?.getTime() ?? 0) - ((a.lastModifiedDate as Date)?.getTime() ?? 0));
        }
        for (let i = 0; i < count; i++) {
            if (chantiers[i].isWithinPointage(clockin.startInfo)) {
                nClockIn.activityMatched = true;
                nClockIn.activityId = chantiers[i].id;
                nClockIn.version++;
                break;
            }
        }
    } else if (clockin.detectionMode === PointageDetectionMode.Manuel) {
        const {chantiers} = ChantierModule;
        const count = chantiers.length;
        const cChantier = (clockin.activityCode ?? '').toLowerCase().trim();
        if (cChantier !== '') {
            for (let i = 0; i < count; i++) {
                if (chantiers[i].name.toLowerCase().trim() === cChantier) {
                    nClockIn.activityMatched = true;
                    nClockIn.activityId = chantiers[i].id;
                    nClockIn.version++;
                    break;
                }
            }
        }
    }

    return nClockIn;
}
