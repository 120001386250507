













































import {Vue, Component} from 'vue-property-decorator';

@Component
export default class AnonymousUserMenu extends Vue {
    public extended = false;
}
