










































































































































































































































































































































































import {Vue, Component, Prop, Watch, PropSync} from 'vue-property-decorator';
import SvgIcon from '@/components/SvgIcon.vue';
import SvgToggleButton from '@/components/SvgToggleButton.vue';

import {
    FicheExpoEPI,
    FicheExpoActivite,
    FicheExpoAgentChimique,
    MaskType
} from '@/entities/fiche-exposition/fiche-exposition.types';
import {ChangeEntrerEnZoneRequestType} from '@/models/viewmodels/fiche/EntrerEnZoneRequestViewModel';

@Component({
    components: {SvgIcon, SvgToggleButton}
})
export default class Step4Component extends Vue {
    @Prop({type: Boolean, required: true, default: () => false})
    private hasMask!: boolean;
    @Prop({type: Number, required: true, default: () => 0})
    private maskNo!: MaskType;
    @PropSync('epi')
    private syncedEpi!: number;
    @PropSync('activite')
    private syncedActivite!: number;
    @PropSync('agents')
    private syncedAgents!: number;
    @PropSync('autresAgents')
    private syncedAutresAgents!: string[];

    private bInit = false;

    @Watch('agents')
    private onAgentsChanged(value: number) {
        this.bInit = true;
        this.initAgents(value);
    }

    @Watch('autresAgents')
    private onAutresAgentsChanged(value: string[]) {
        this.bInit = true;
        this.initAutresAgents(value);
    }

    private aAutresAgents: string[] = [];

    private colors: string[] = ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'];
    private agentsChimiquesProposes: any[] = [
        {header: "Selectionner des agents chimiques ou en ajouter d'autres."}
    ];
    private agentsChimiquesCombines: any[] = [];
    private search: string | null = null;

    @Watch('agentsChimiquesCombines')
    private agentsChimiquesCombinesChanged(val: any[], prev: any[]) {
        if (val.length === prev.length) {
            this.bInit = false;
            return;
        }

        const values: string[] = [];
        const others: string[] = [];
        let hasOther = false;

        this.agentsChimiquesCombines = val.map(v => {
            if (typeof v === 'string') {
                // eslint-disable-next-line no-param-reassign
                v = {
                    text: v,
                    value: `Autre:${v}`,
                    color: ''
                };

                if (!hasOther) {
                    values.push('Autre');
                    hasOther = true;
                }
                others.push(v.text);

                const foundIndex = this.agentsChimiquesProposes.findIndex(
                    item => item.value === v.value
                );
                if (foundIndex >= 0) {
                    return v;
                }

                this.agentsChimiquesProposes = [...this.agentsChimiquesProposes, v];
            } else if (v.value.startsWith('Autre:')) {
                others.push(v.text);
                if (!hasOther) {
                    values.push('Autre');
                    hasOther = true;
                }
            } else {
                values.push(v.value);
            }

            return v;
        });

        this.agentsChimiques = values;
        this.aAutresAgents = others;
        if (!this.bInit) {
            this.$emit('update:autresAgents', this.aAutresAgents);

            this.$emit('changeoption', {
                changeType:
                    ChangeEntrerEnZoneRequestType.AgentsChimiques |
                    ChangeEntrerEnZoneRequestType.AutreAgentsChimiques,
                params: {
                    autresAgentsChimiques: this.aAutresAgents
                }
            });
        }
        this.bInit = false;
    }

    private created() {
        this.agentsChimiquesProposes = [
            ...this.agentsChimiquesProposes,
            ...this.allAgentsChimiques
                .filter(agent => agent.value !== 'Autre')
                .map((agent, index) => {
                    return {
                        text: agent.text,
                        value: agent.value,
                        color: this.colors[index % 5]
                    };
                })
        ];
        this.bInit = true;
        this.initAgents(this.syncedAgents);
        this.bInit = true;
        this.initAutresAgents(this.syncedAutresAgents);
    }

    private initAgents(value: number) {
        // eslint-disable-next-line no-underscore-dangle
        const _agentsChimiques: {
            [idx: string]: FicheExpoAgentChimique;
        } = FicheExpoAgentChimique as any;
        // tslint:disable-next-line:no-bitwise
        this.agentsChimiquesCombines = this.allAgentsChimiques
            .map((element, index: number) => {
                if (
                    (value & _agentsChimiques[element.value]) !== _agentsChimiques[element.value] ||
                    element.value === 'Autre'
                ) {
                    return null;
                }
                return {
                    text: element.text,
                    value: element.value,
                    color: this.colors[index % 5]
                };
            })
            .filter(obj => obj !== null);
    }

    private initAutresAgents(value: string[]) {
        if (!value) {
            this.aAutresAgents = [];
            return;
        }

        this.aAutresAgents = value;
        const others: any[] = [...this.agentsChimiquesCombines];
        value.forEach(text => {
            const foundIndex = this.agentsChimiquesCombines.findIndex(
                item => item.value === `Autre:${text}`
            );
            if (foundIndex >= 0) {
                return;
            }

            others.push({
                text,
                value: `Autre:${text}`,
                color: ''
            });
        });
        this.agentsChimiquesCombines = others;
    }

    private get FicheExpoActiviteProp() {
        return FicheExpoActivite;
    }

    private get isCombiChecked() {
        return this.getEPI(FicheExpoEPI.Combinaison);
    }

    private set isCombiChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Combinaison);
    }

    private get isGantsChecked() {
        return this.getEPI(FicheExpoEPI.Gants);
    }

    private set isGantsChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Gants);
    }

    private get isBottesChecked() {
        return this.getEPI(FicheExpoEPI.Bottes);
    }

    private set isBottesChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Bottes);
    }

    private get isSousVetementsChecked() {
        return this.getEPI(FicheExpoEPI.SousVetements);
    }

    private set isSousVetementsChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.SousVetements);
    }

    private get isDoubleCombiChecked() {
        return this.getEPI(FicheExpoEPI.DoubleCombi);
    }

    private set isDoubleCombiChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.DoubleCombi);
    }

    private get isSurBottesChecked() {
        return this.getEPI(FicheExpoEPI.SurBottes);
    }

    private set isSurBottesChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.SurBottes);
    }

    private get isLunettesChecked() {
        return this.getEPI(FicheExpoEPI.Lunettes);
    }

    private set isLunettesChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Lunettes);
    }

    private get isHarnaisChecked() {
        return this.getEPI(FicheExpoEPI.Harnais);
    }

    private set isHarnaisChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Harnais);
    }

    private get isCasqueChecked() {
        return this.getEPI(FicheExpoEPI.Casque);
    }

    private set isCasqueChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.Casque);
    }

    private get isAntibruitChecked() {
        return this.getEPI(FicheExpoEPI.AntiBruit);
    }

    private set isAntibruitChecked(value: boolean) {
        this.setEpi(value, FicheExpoEPI.AntiBruit);
    }

    private get isAspirationChecked() {
        return this.getActivite(FicheExpoActivite.Aspiration);
    }

    private set isAspirationChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.Aspiration);
    }

    private get isConfinementChecked() {
        return this.getActivite(FicheExpoActivite.Confinement);
    }

    private set isConfinementChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.Confinement);
    }

    private get isDepoussierageChecked() {
        return this.getActivite(FicheExpoActivite.Depoussierement);
    }

    private set isDepoussierageChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.Depoussierement);
    }

    private get isNettoyageFinChecked() {
        return this.getActivite(FicheExpoActivite.NettoyageFin);
    }

    private set isNettoyageFinChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.NettoyageFin);
    }

    private get isRetraitChecked() {
        return this.getActivite(FicheExpoActivite.Retrait);
    }

    private set isRetraitChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.Retrait);
    }

    private get isReplisChecked() {
        return this.getActivite(FicheExpoActivite.Replis);
    }

    private set isReplisChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.Replis);
    }

    private get isEvacuationDechetsChecked() {
        return this.getActivite(FicheExpoActivite.EvacuationDechets);
    }

    private set isEvacuationDechetsChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.EvacuationDechets);
    }

    private get isInstallationGeneraleChecked() {
        return this.getActivite(FicheExpoActivite.InstallationGenerale);
    }

    private set isInstallationGeneraleChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.InstallationGenerale);
    }

    private get isRepliGeneralChecked() {
        return this.getActivite(FicheExpoActivite.RepliGeneral);
    }

    private set isRepliGeneralChecked(value: boolean) {
        this.setActivite(value, FicheExpoActivite.RepliGeneral);
    }

    private getEPI(field: FicheExpoEPI) {
        return (this.syncedEpi & field) === field;
    }

    private setEpi(value: boolean, field: FicheExpoEPI) {
        let resultType = this.syncedEpi;
        if (value) {
            resultType |= field;
        } else {
            resultType &= ~field;
        }
        this.syncedEpi = resultType;
    }

    private getActivite(field: FicheExpoActivite) {
        return (this.syncedActivite & field) === field;
    }

    private setActivite(value: boolean, field: FicheExpoActivite) {
        let resultType = this.syncedActivite;
        if (value) {
            resultType |= field;
        } else {
            resultType &= ~field;
        }
        this.syncedActivite = resultType;
    }

    private isActivityAvailable(field: FicheExpoActivite) {
        if (!this.hasMask) {
            return (
                field === FicheExpoActivite.Confinement ||
                field === FicheExpoActivite.Replis ||
                field === FicheExpoActivite.InstallationGenerale ||
                field === FicheExpoActivite.RepliGeneral
            );
        }

        switch (this.maskNo) {
            case MaskType.DemiMasque:
                return (
                    field === FicheExpoActivite.Confinement ||
                    field === FicheExpoActivite.Replis ||
                    field === FicheExpoActivite.EvacuationDechets ||
                    field === FicheExpoActivite.InstallationGenerale ||
                    field === FicheExpoActivite.RepliGeneral
                );
            case MaskType.MasqueAdductionAir:
            case MaskType.MasqueVentilationAssistee:
                return (
                    field === FicheExpoActivite.Replis ||
                    field === FicheExpoActivite.Confinement ||
                    field === FicheExpoActivite.EvacuationDechets ||
                    field === FicheExpoActivite.Aspiration ||
                    field === FicheExpoActivite.Retrait ||
                    field === FicheExpoActivite.NettoyageFin ||
                    field === FicheExpoActivite.InstallationGenerale ||
                    field === FicheExpoActivite.RepliGeneral
                );
            default:
                break;
        }
        return false;
    }

    private get computedCanGoToNextStep() {
        return this.syncedEpi !== 0 && this.syncedActivite !== 0 && this.syncedAgents !== 0;
    }

    private filter(item: any, queryText: string, itemText: string) {
        if (item.header) return false;

        const hasValue = (val: string): string => (val != null ? val : '');

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
            text
                .toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
        );
    }

    private get agentsChimiquesProperties(): string[] {
        // eslint-disable-next-line no-restricted-globals
        return Object.keys(FicheExpoAgentChimique).filter(key => isNaN(Number(key)));
    }

    private get allAgentsChimiques(): any[] {
        return this.agentsChimiquesProperties.map((element, index) => {
            return {
                text: (this.$i18n.t('agentsChimiques').valueOf() as string[])[index],
                value: element
            };
        });
    }

    private get agentsChimiques(): string[] {
        // eslint-disable-next-line no-underscore-dangle
        const _agentsChimiques: {
            [idx: string]: FicheExpoAgentChimique;
        } = FicheExpoAgentChimique as any;
        // tslint:disable-next-line:no-bitwise
        return this.agentsChimiquesProperties.filter(
            key => this.syncedAgents & _agentsChimiques[key]
        );
    }

    private set agentsChimiques(value: string[]) {
        let resultType = 0;
        // eslint-disable-next-line no-underscore-dangle
        const _agentsChimiques: {
            [idx: string]: FicheExpoAgentChimique;
        } = FicheExpoAgentChimique as any;
        value.forEach((element: string) => {
            // tslint:disable-next-line:no-bitwise
            resultType |= _agentsChimiques[element];
        });
        this.syncedAgents = resultType;
    }

    private get isAutresAgentsChimiquesEnabled() {
        return (this.syncedAgents & FicheExpoAgentChimique.Autre) === FicheExpoAgentChimique.Autre;
    }

    private goToNextStep() {
        this.$emit('gotonext');
    }
}
