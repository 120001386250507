






























































import {Vue, Component, Watch} from 'vue-property-decorator';
import {PointageModule} from '@/store/modules/pointage';
import {ApplicationModule} from '@/store/modules/application';
import {ExpositionModule} from '@/store/modules/exposition';
import {UserModule} from '@/store/modules/user';
import EnteringZoneDialogComponent from '@/components/Zone/EnteringZoneDialog.vue';

@Component({
    components: {
        EnteringZoneDialogComponent,
    },
})
export default class UserZoneTimeTrackerComponent extends Vue {
    private enteringZoneProx = false;

    private now = new Date();

    private interval: number | null = null;

    private get loading() {
        return ApplicationModule.loading;
    }

    private get step() {
        return ExpositionModule.ficheEnCours?.etape || -1;
    }

    private get inZone() {
        return this.step >= 3 && this.step < 7;
    }

    private get hasMask() {
        return ExpositionModule.ficheEnCours?.possedeMasque;
    }

    private get pointageEnCours() {
        return PointageModule.currentClockInData;
    }

    private get clocking() {
        return this.pointageEnCours !== undefined;
    }

    private get computedEnterExitZoneButtonLabel() {
        return !this.inZone
            ? this.$t('pointage.enterZoneButton').toString()
            : this.$t('pointage.exitZoneButton').toString();
    }

    private get hasMedicalVisitOk() {
        return UserModule.medicalVisitOk;
    }

    private get hasSS3orSS4() {
        return UserModule.hasSS3orSS4;
    }

    private get canEnterInZone() {
        return this.hasMedicalVisitOk && this.hasSS3orSS4;
    }

    private get mustWaitBeforeReenter() {
        return ExpositionModule.hasWaitingTime !== -1;
    }

    private get computedZoneIconColor() {
        return !this.inZone ? 'error' : 'success';
    }

    private get computedZoneIconName() {
        return !this.inZone ? 'mdi-alarm-plus' : 'mdi-alarm-check';
    }

    private async handleZoneClick() {
        this.enteringZoneProx = true;
    }

    private mounted() {
        this.interval = window.setInterval(() => {
            this.now = new Date();
        });
    }

    private destroyed() {
        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    private showTime(type: string) {
        if (type === 'AZ') {
            return new Date(this.tempsAvantZone * 1000).toISOString().substr(11, 8);
        }
        if (type === 'EZ') {
            return new Date(this.tempsEnZone * 1000).toISOString().substr(11, 8);
        }
        if (type === 'SZ') {
            return new Date(this.tempsSortieZone * 1000).toISOString().substr(11, 8);
        }
        if (type === 'R') {
            return new Date(this.tempsRecuperation * 1000).toISOString().substr(11, 8);
        }
        return '-';
    }

    @Watch('enteringZoneProx')
    private onEnteringZoneChanged() {
        if (this.enteringZoneProx) {
            return;
        }
        this.now = new Date();
    }

    private get tempsAvantZone() {
        const tempsSec = ExpositionModule.ficheEnCours?.getTempsAvantZoneEnSeconde(this.now) || 0;
        if (this.step === 3 && tempsSec > 0) {
            return tempsSec;
        }
        return 0;
    }

    private get tempsRecuperation() {
        const dateMS = ExpositionModule.hasWaitingTime;
        if (dateMS > 0) {
            return Math.max(0, (dateMS - this.now.getTime()) / 1000);
        }
        return 0;
    }

    private get tempsEnZone() {
        const tempsSec =
            ExpositionModule.ficheEnCours?.getTempsRestantEnZoneEnSeconde(this.now) || 0;
        if (this.step === 3 && tempsSec > 0) {
            return tempsSec;
        }
        return 0;
    }

    private get tempsSortieZone() {
        const tempsSec = ExpositionModule.ficheEnCours?.getTempsAvantSortieEnSeconde(this.now) || 0;
        if (this.step === 3 && tempsSec > 0) {
            // 7 minutes pour sortir de zone
            return tempsSec;
        }
        return 0;
    }

    private get computedButtonColor() {
        if (this.inZone) {
            return 'green darken-1';
        }
        return 'red darken-1';
    }
}

Vue.component('user-zone-time-tracker-component', UserZoneTimeTrackerComponent);
