




















































































































































import {Vue, Component} from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import filters from '@/modules/filters';
import {
    PointageTransportMode,
    PointageDetectionMode,
    IClockInData,
} from '@/entities/pointage/pointage.types';
import {PointageModule} from '@/store/modules/pointage';
import {ApplicationModule} from '@/store/modules/application';
import {ClockingChangeType} from '@/models/viewmodels/user/ClockingExtraProperty';
import {ChantierModule} from '@/store/modules/chantier';
import {UserModule} from '@/store/modules/user';

@Component({
    components: {},
    filters,
})
export default class UserTimeTrackerComponent extends Vue {
    private picture: any = null;

    private messageChantier = '';

    private searchChantier = '';
    private cancelDebounce: Array<boolean> = [false, false, false];
    private temporizing = false;

    private debouncer!: (value: string, mutation: ClockingChangeType) => void;

    public get pointageEnCours(): IClockInData | undefined {
        return PointageModule.currentClockInData;
    }

    private get listChantiers() {
        return ChantierModule.chantiers;
    }

    private get computedChantiersDispo() {
        const list = ChantierModule.chantiers.map((item) => item.name);
        if (this.codeChantier !== '' && list.indexOf(this.codeChantier) < 0) {
            list.push(this.codeChantier);
        }
        return list;
    }

    private get clocking() {
        return this.pointageEnCours !== undefined;
    }

    public get clockingTimeMin(): number {
        return Math.floor(PointageModule.clockingTime / 60);
    }

    public get isTooEarly(): boolean {
        return this.clocking && PointageModule.clockingTime < 300;
    }

    public get modeTransportProperties(): string[] {
        // eslint-disable-next-line no-restricted-globals
        return Object.keys(PointageTransportMode).filter((key) => isNaN(Number(key)));
    }

    public get modeDetectionChantierProperties(): string[] {
        // eslint-disable-next-line no-restricted-globals
        return Object.keys(PointageDetectionMode).filter((key) => isNaN(Number(key)));
    }

    public get transportMode(): string {
        const selMode = this.pointageEnCours?.transportMode ?? PointageTransportMode.NULL;
        const tMode =
            selMode === PointageTransportMode.ByMyself || selMode === PointageTransportMode.Company
                ? selMode
                : UserModule.lastChosenTransportMode;
        return this.modeTransportProperties[tMode];
    }

    public set transportMode(value: string) {
        const tMode = this.modeTransportProperties.indexOf(value);
        PointageModule.updateCurrent({
            value: tMode,
            mutation: ClockingChangeType.Transport,
        });
    }

    public get transportModeChosen(): boolean {
        const selMode = this.pointageEnCours?.transportMode ?? PointageTransportMode.NULL;
        const tMode =
            selMode === PointageTransportMode.ByMyself || selMode === PointageTransportMode.Company
                ? selMode
                : UserModule.lastChosenTransportMode;
        return tMode === PointageTransportMode.ByMyself || tMode === PointageTransportMode.Company;
    }

    public get modeChantier(): string {
        const tMode = this.pointageEnCours?.detectionMode || PointageDetectionMode.Automatique;
        return this.modeDetectionChantierProperties[tMode];
    }

    public set modeChantier(value: string) {
        const tMode = this.modeDetectionChantierProperties.indexOf(value);
        PointageModule.updateCurrent({
            value: tMode,
            mutation: ClockingChangeType.Detection,
        });
    }

    public get chantierActuel(): string {
        const activityMatched = this.pointageEnCours?.activityMatched;
        const idChantier = this.pointageEnCours?.activityId;
        if (!activityMatched || !idChantier) {
            return 'Aucun chantier détecté.';
        }
        const chantier = this.listChantiers.find((item) => item.id === idChantier);
        return `Chantier ou activité : ${chantier?.name || 'nom indisponible'}`;
    }

    public get codeChantier(): string {
        return this.pointageEnCours?.activityCode ?? '';
    }

    public set codeChantier(value: string) {
        this.debouncer(value, ClockingChangeType.CodeChantier);
    }

    public forceValidateCodeChantier(value: string): void {
        if (value !== this.codeChantier) {
            this.cancelDebounce[0] = true;
            PointageModule.updateCurrent({
                value,
                mutation: ClockingChangeType.CodeChantier,
            });
        }
    }

    private get description() {
        return this.pointageEnCours?.description || '';
    }

    private set description(value: string) {
        this.debouncer(value, ClockingChangeType.Description);
    }

    private forceValidateDescription(value: string) {
        if (value !== this.description) {
            this.cancelDebounce[1] = true;
            PointageModule.updateCurrent({
                value,
                mutation: ClockingChangeType.Description,
            });
        }
    }

    public get isWorkingRemotely(): boolean {
        return this.pointageEnCours?.isWorkingRemotely ?? false;
    }

    public set isWorkingRemotely(value: boolean) {
        if (value !== this.isWorkingRemotely) {
            PointageModule.updateCurrent({
                value,
                mutation: ClockingChangeType.IsWorkingRemotely,
            });
        }
    }

    private get computedStartStopButtonLabel() {
        if (this.clocking) {
            return this.$i18n.t('pointage.stopButton');
        }
        return this.$i18n.t('pointage.startButton');
    }

    private get computedCardColor() {
        if (this.clocking) {
            return this.pointageEnCours?.activityMatched ? 'success' : 'warning';
        }
        return 'primary';
    }

    private get loading() {
        return ApplicationModule.loading;
    }

    private mounted() {
        this.$nextTick(() => {
            this.debouncer = debounce((value: string, mutation: ClockingChangeType) => {
                const debounceIndex = Math.log(mutation) / Math.log(2) - 2;
                if (this.cancelDebounce[debounceIndex]) {
                    this.cancelDebounce[debounceIndex] = false;
                    return;
                }
                PointageModule.updateCurrent({
                    value,
                    mutation,
                });
                if (this.temporizing) {
                    this.temporizing = false;
                }
            }, 1500);
        });
    }

    private async locateMe(event: Event) {
        this.temporizing = true;
        event.stopPropagation();

        PointageModule.startStopPointage();
        setTimeout(() => {
            this.temporizing = false;
        }, 30000);
    }

    private handleClickQrCode() {
        this.$router.push('/qrcode');
    }
}
