




import {Component, Vue} from 'vue-property-decorator';
import {ApplicationModule} from '../store/modules/application';

@Component({})
export default class ApplicationLoading extends Vue {
    private get loading() {
        return ApplicationModule.loading;
    }
}
