import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {to} from '@/utils';
import { IAgenceData } from '@/entities/agence/agence.types';

export interface IAgenceState {
    agences: Record<string, string>;
}

@Module({dynamic: true, store, name: 'agence', namespaced: true})
class MAgence extends VuexModule implements IAgenceState {
    public agences: Record<string, string> = {};

    @Mutation
    private SET_AGENCES(agences: Record<string, string>) {
        this.agences = agences;
    }

    @Action
    public async initializeStore() {
        const [, agences] = await to(myPyropDb.getAgences());

        if (!agences) {
            return;
        }

        const ag: Record<string, string> = {};
        agences.forEach(agence => {
            ag[agence.id] = agence.name;
        });

        if (agences && Array.isArray(agences) && agences.length > 0) {
            this.SET_AGENCES(ag);
        }
    }

    @Action
    public async serverAgenceDataUpdate(data: Record<string, string>) {
        if (data) {
            const agences: IAgenceData[] = [];
            Object.keys(data).forEach(id => agences.push({
                id,
                name: data[id]
            }));
            myPyropDb.setAgences(agences);
        }
        this.SET_AGENCES(data ?? {});
    }
}

export const AgenceModule = getModule(MAgence);
