


























































































































import {Vue, Component} from 'vue-property-decorator';
import {UserModule} from '@/store/modules/user';
import {hasClaim} from '@/utils';

@Component({})
export default class LoggedUserMenu extends Vue {
    private extended = false;
    private hasClaim = hasClaim;

    public get computedPictureUrl() {
        if (!UserModule.pictureUrl) {
            return null;
        }
        return `${process.env.VUE_APP_BASE_URL}${UserModule.pictureUrl}`;
    }

    private get firstName() {
        return UserModule.firstName || '';
    }

    private get lastName() {
        return UserModule.lastName || '';
    }

    private get claims() {
        return UserModule.claims || [];
    }

    private get avInitial() {
        const name = `${this.firstName} ${this.lastName}`;
        const initials = name.match(/\b\w/g) || [];
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }

    private async logout() {
        await UserModule.logout();
        this.extended = false;
        this.$router.push('/account/login');
    }
}
