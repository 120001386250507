import {addDays} from '@/utils';

/* eslint no-underscore-dangle: 0 */
const _defineProperty = (obj: any, key: string, value: any) => {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        // eslint-disable-next-line no-param-reassign
        obj[key] = value;
    }
    return obj;
};

const paques = (year: number) => {
    // Calcul du jour de pâques (algorithme de Oudin (1940))
    // Calcul du nombre d'or - 1
    const goldNumber = year % 19;
    // Année divisé par cent
    const anneeDiv100 = Math.floor(year / 100);
    // intEpacte est = 23 - Epacte (modulo 30)
    const epacte =
        (anneeDiv100 -
            Math.floor(anneeDiv100 / 4) -
            Math.floor((8 * anneeDiv100 + 13) / 25) +
            19 * goldNumber +
            15) %
        30;
    // Le nombre de jours à partir du 21 mars pour atteindre la pleine lune Pascale
    const daysEquinoxeToMoonFull =
        epacte -
        Math.floor(epacte / 28) *
            (1 -
                Math.floor(epacte / 28) *
                    Math.floor(29 / (epacte + 1)) *
                    Math.floor((21 - goldNumber) / 11));
    // Jour de la semaine pour la pleine lune Pascale (0=dimanche)
    const weekDayMoonFull =
        (year +
            Math.floor(year / 4) +
            daysEquinoxeToMoonFull +
            2 -
            anneeDiv100 +
            Math.floor(anneeDiv100 / 4)) %
        7;
    // Nombre de jours du 21 mars jusqu'au dimanche de ou
    // avant la pleine lune Pascale (un nombre entre -6 et 28)
    const daysEquinoxeBeforeFullMoon = daysEquinoxeToMoonFull - weekDayMoonFull;
    // mois de pâques
    const monthPaques = 3 + Math.floor((daysEquinoxeBeforeFullMoon + 40) / 44);
    // jour de pâques
    const dayPaques = daysEquinoxeBeforeFullMoon + 28 - 31 * Math.floor(monthPaques / 4);

    return new Date(year, monthPaques - 1, dayPaques);
};

const lundiDePaques = (year: number) => {
    return addDays(paques(year), 1);
};

const vendrediSaint = (year: number) => {
    return addDays(paques(year), -2);
};

const ascension = (year: number) => {
    return addDays(paques(year), 39);
};

/* Désactivé pour 2021
const lundiDePentecote = (year: number) => {
    return addDays(paques(year), 50);
}; */

const jourDeLAn = (year: number) => {
    return new Date(year, 0, 1);
};

const feteDuTravail = (year: number) => {
    return new Date(year, 4, 1);
};

const victoireDesAllies = (year: number) => {
    return new Date(year, 4, 8);
};

const feteNationale = (year: number) => {
    return new Date(year, 6, 14);
};

const assomption = (year: number) => {
    return new Date(year, 7, 15);
};

const toussaint = (year: number) => {
    return new Date(year, 10, 1);
};

const armistice = (year: number) => {
    return new Date(year, 10, 11);
};

const noel = (year: number) => {
    return new Date(year, 11, 25);
};

const saintEtienne = (year: number) => {
    return new Date(year, 11, 26);
};

const fetes: {
    [key: string]: (year: number) => Date;
} = {
    "Jour de l'an": jourDeLAn,
    'Fête du travail': feteDuTravail,
    'Victoire des alliés': victoireDesAllies,
    'Fête Nationale': feteNationale,
    'Assomption': assomption,
    'Toussaint': toussaint,
    'Armistice': armistice,
    'Noël': noel,
    'Lundi de Pâques': lundiDePaques,
    'Ascension': ascension,
/*    'Lundi de Pentecôte': lundiDePentecote, */ // Désactivation en 2021 du lundi de pentecôte
    'Vendredi Saint': vendrediSaint,
    'Saint Étienne': saintEtienne
};
const fetesAlsace = ['Vendredi Saint', 'Saint Étienne'];

export const getJoursFeries = (year: number, {alsace}: {alsace: boolean}): {[fete: string]: Date } => {
    return Object.keys(fetes) // filter out alsace if needed
        .filter((fete) => {
            return alsace || !fetesAlsace.includes(fete);
        }) // get results
        .map(fete => {
            return _defineProperty({}, fete, fetes[fete](year));
        }) // flatten dict
        .reduce((acc, fete) => {
            return Object.assign(acc, fete);
        }, {});
};

/* eslint-disable-next-line no-shadow */
export enum JoursFeriesFR {
    Jour1An,
    FeteTravail,
    VictoireAllies,
    FeteNationale,
    Assomption,
    Toussaint,
    Armistice,
    Noel,
    LundiPaques,
    Ascension,
    LundiPentecote,
    VendrediSaint,
    SaintEtienne
}
