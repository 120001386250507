import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import cloneDeep from 'lodash/cloneDeep';
import {
    IFicheExpositionData,
    FicheExpoAgentChimique
} from '@/entities/fiche-exposition/fiche-exposition.types';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {ISyncActionType} from '@/models/application/sync-action';
import {ChangeEntrerEnZoneRequestType} from '@/models/viewmodels/fiche/EntrerEnZoneRequestViewModel';
import {FicheExposition} from '@/entities/fiche-exposition/fiche-exposition';
import {shortGuid, isToday, reviveUTCDate, to} from '@/utils';
import {getFichesExpositions} from '@/api/exposition';
import {SyncModule} from './synchro';

export interface IExpoState {
    fiches: Array<IFicheExpositionData>;
    ficheId: string | null;
    temporaryFiches: Array<IFicheExpositionData>;
    selectedId: string | null;
}

@Module({dynamic: true, store, name: 'exposition', namespaced: true})
class MExpo extends VuexModule implements IExpoState {
    public fiches: Array<IFicheExpositionData> = [];
    public ficheId: string | null = null;
    public temporaryFiches: Array<IFicheExpositionData> = [];
    public selectedId: string | null = null;

    @Mutation
    private SET_FICHES(fiches: Array<IFicheExpositionData>) {
        this.fiches = fiches;
    }

    @Mutation
    private SET_FICHE(fiche: IFicheExpositionData) {
        const fiches = [...this.fiches];
        const pIndex = fiches.findIndex(p => p.id === fiche.id);
        if (pIndex !== -1) {
            fiches.splice(pIndex, 1);
        }
        fiches.push(fiche);
        this.fiches = fiches;
    }

    @Mutation
    private SET_FICHEID(id: string | null) {
        this.ficheId = id;
    }

    @Mutation
    private SET_TEMPFICHES(fiches: Array<IFicheExpositionData>) {
        this.temporaryFiches = fiches;
    }

    @Mutation
    private SET_SELECTEDID(id: string | null) {
        this.selectedId = id;
    }

    @Action
    public updateCurrent(payload: {value: any; mutation: ChangeEntrerEnZoneRequestType}) {
        const {value, mutation} = payload;
        if (
            (mutation & ChangeEntrerEnZoneRequestType.Creer) ===
            ChangeEntrerEnZoneRequestType.Creer
        ) {
            if (this.ficheId) {
                return;
            }
            const nFiche: IFicheExpositionData = {
                activites: 0,
                agentsChimiques: FicheExpoAgentChimique.Chrysotile,
                agentsChimiquesAutres: [],
                chantierId: null,
                dateInitialisation: new Date(),
                epi: 0,
                etape: 0,
                id: shortGuid(),
                masque: 0,
                possedeMasque: false,
                version: 0,
                tempsEnZoneEnSeconde: 0,
                tempsMaxAccordeEnSeconde: 0
            };
            this.SET_FICHES([...this.fiches, nFiche]);
            this.SET_FICHEID(nFiche.id);
        }

        if (!this.ficheId) {
            return;
        }

        const fiche = this.ficheEnCours as FicheExposition;
        if (
            (mutation & ChangeEntrerEnZoneRequestType.PossedeMasque) ===
            ChangeEntrerEnZoneRequestType.PossedeMasque
        ) {
            fiche.possedeMasque = value;
            if (!value) {
                // Date d'entrée en zone = maintenant SANS les + 3 minutes;
                fiche.dateEntreeEnZone = new Date();
                fiche.etape = 3;
                fiche.tempsMaxAccordeEnSeconde = -1;
            } else {
                fiche.etape = 1;
            }
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.Masque) ===
            ChangeEntrerEnZoneRequestType.Masque
        ) {
            fiche.masque = value;
            fiche.etape = 2;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.Temperature) ===
            ChangeEntrerEnZoneRequestType.Temperature
        ) {
            fiche.temperature = value;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.TauxEmpoussierement) ===
            ChangeEntrerEnZoneRequestType.TauxEmpoussierement
        ) {
            fiche.tauxEmpoussierement = value;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.EntrerAvecMasque) ===
            ChangeEntrerEnZoneRequestType.EntrerAvecMasque
        ) {
            let timeLeft = 6 * 3600 - this.totalTimeToday;
            if (timeLeft > 2.5 * 3600) {
                timeLeft = 2.5 * 3600;
            }
            // Date d'entrée en zone = maintenant + 3 minutes;
            fiche.dateEntreeEnZone = new Date(new Date().getTime() + 3 * 60 * 1000);
            fiche.etape = 3;
            fiche.tempsMaxAccordeEnSeconde = timeLeft;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.SortirDeZone) ===
            ChangeEntrerEnZoneRequestType.SortirDeZone
        ) {
            if (fiche.possedeMasque) {
                fiche.dateSortieDeZone = new Date(new Date().getTime() - 7 * 60 * 1000);
                fiche.dateFinRecuperation = new Date(
                    fiche.dateSortieDeZone.getTime() + 20 * 60 * 1000
                ); // 20 minutes de temps de récupération
            } else {
                fiche.dateSortieDeZone = new Date(); // Sortie sans les -7 minutes.
                // pas de récupération sur les sessions de travail sans masque
            }

            if (
                reviveUTCDate(fiche.dateSortieDeZone).getTime() <=
                reviveUTCDate(fiche.dateEntreeEnZone as Date).getTime()
            ) {
                fiche.etape = -1;
                this.SET_FICHEID(null);
            } else {
                fiche.etape = 4;
            }
        }
        if ((mutation & ChangeEntrerEnZoneRequestType.EPI) === ChangeEntrerEnZoneRequestType.EPI) {
            fiche.epi = value;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.Activite) ===
            ChangeEntrerEnZoneRequestType.Activite
        ) {
            fiche.activites = value;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.AgentsChimiques) ===
            ChangeEntrerEnZoneRequestType.AgentsChimiques
        ) {
            let fixedValue = value;
            if (
                (!fiche.agentsChimiquesAutres ||
                    !Array.isArray(fiche.agentsChimiquesAutres) ||
                    fiche.agentsChimiquesAutres.length === 0) &&
                (value & FicheExpoAgentChimique.Autre) === FicheExpoAgentChimique.Autre
            ) {
                fixedValue &= ~FicheExpoAgentChimique.Autre;
            }
            fiche.agentsChimiques = fixedValue;
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.AutreAgentsChimiques) ===
            ChangeEntrerEnZoneRequestType.AutreAgentsChimiques
        ) {
            if (value && Array.isArray(value) && value.length > 0) {
                fiche.agentsChimiques |= FicheExpoAgentChimique.Autre;
                fiche.agentsChimiquesAutres = value;
            } else {
                fiche.agentsChimiques &= ~FicheExpoAgentChimique.Autre;
                fiche.agentsChimiquesAutres = [];
            }
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.ValiderInformations) ===
            ChangeEntrerEnZoneRequestType.ValiderInformations
        ) {
            if (fiche.possedeMasque && (fiche.etape >= 4 || fiche.etape < 6)) {
                fiche.etape++;
            } else {
                // Skip étape 5 (temps de récupération) si on ne porte pas de masque
                fiche.etape = 6;
            }
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.SignerFiche) ===
            ChangeEntrerEnZoneRequestType.SignerFiche
        ) {
            fiche.etape = 7;
            fiche.signature = value;
            this.SET_FICHEID(null);
        }
        if (
            (mutation & ChangeEntrerEnZoneRequestType.Annuler) ===
            ChangeEntrerEnZoneRequestType.Annuler
        ) {
            fiche.etape = -1;
            this.SET_FICHEID(null);
        }

        fiche.version++;

        SyncModule.addSyncAction({
            aType: ISyncActionType.FicheExposition,
            payload: {mutation, fiche}
        });

        myPyropDb.setFicheExpo(fiche);
        this.SET_FICHE(fiche);
    }

    @Action
    public async initializeStore() {
        const [, fiches] = await to(myPyropDb.getFichesExpo());

        if (fiches && Array.isArray(fiches) && fiches.length > 0) {
            let maxDate!: Date | undefined;
            let fId!: string | undefined;

            const mappedFiches = fiches.map(elem => new FicheExposition(elem));
            mappedFiches.forEach(f => {
                if (!isToday(f.dateInitialisation) || f.etape === -1 || f.etape === 7) {
                    return;
                }
                if (!maxDate || maxDate < f.dateInitialisation) {
                    maxDate = f.dateInitialisation;
                    fId = f.id;
                }
            });
            this.SET_FICHES(mappedFiches);
            this.SET_FICHEID(fId || null);
        }
    }

    @Action
    public async serverFichesExpoDataUpdate(data: Array<IFicheExpositionData>) {
        const fiches = cloneDeep(this.fiches);
        
        let maxDate!: Date | undefined;
        let fId!: string | undefined;

        data.forEach(fiche => {
            const fExpo = new FicheExposition(fiche);
            if (isToday(fExpo.dateInitialisation) && 
                fExpo.etape > -1 &&
                fExpo.etape < 7 &&
                (!maxDate || maxDate < fExpo.dateInitialisation)
            ) {
                maxDate = fExpo.dateInitialisation;
                fId = fExpo.id;
            }
            const pIndex = fiches.findIndex(fi => fi.id === fiche.id);
            if (pIndex !== -1) {
                if (fiche.version <= fiches[pIndex].version) {
                    // ignore this version
                    return;
                }
                // Got new version of this pointage
                fiches.splice(pIndex, 1);
            }
            fiches.push(new FicheExposition(fiche));
            myPyropDb.setFicheExpo(fiche);
        });

        this.SET_FICHES(fiches);
        this.SET_FICHEID(fId || null);
    }

    @Action
    public async getFichesExposition(dateStr: string) {
        const [, data] = await to(getFichesExpositions(dateStr));
        if (data) {
            this.SET_TEMPFICHES(data.map(elem => new FicheExposition(elem)));
        } else {
            this.SET_TEMPFICHES([]);
        }
    }

    public get ficheEnCours() {
        if (!this.ficheId) {
            return undefined;
        }
        const pIndex = this.fiches.findIndex(p => p.id === this.ficheId);
        if (pIndex === -1) {
            return undefined;
        }

        return new FicheExposition(this.fiches[pIndex]);
    }

    public get selectedFiche() {
        if (!this.selectedId) {
            return undefined;
        }
        const pIndex = this.temporaryFiches.findIndex(p => p.id === this.selectedId);
        if (pIndex === -1) {
            return undefined;
        }

        return new FicheExposition(this.temporaryFiches[pIndex]);
    }

    public get totalTimeToday() {
        const filteredFichesTimes = this.fiches
            .filter(
                f =>
                    f.dateEntreeEnZone &&
                    f.masque &&
                    f.etape === 7 &&
                    isToday(reviveUTCDate(f.dateEntreeEnZone))
            )
            .map(f => f.tempsEnZoneEnSeconde);
        if (filteredFichesTimes.length === 0) {
            return 0;
        }
        return filteredFichesTimes.reduce((total: number, num: number) => {
            return total + num;
        });
    }

    public get amountFichesToday() {
        return this.fiches.filter(
            f =>
                f.dateEntreeEnZone &&
                f.masque &&
                f.etape === 7 &&
                isToday(reviveUTCDate(f.dateEntreeEnZone))
        ).length;
    }

    public get hasWaitingTime() {
        const filteredFichesTimes = this.fiches
            .filter(
                f =>
                    f.possedeMasque &&
                    f.dateFinRecuperation &&
                    f.etape > 3 &&
                    isToday(reviveUTCDate(f.dateFinRecuperation))
            )
            .map(f => reviveUTCDate(f.dateFinRecuperation as string).getTime());
        if (filteredFichesTimes.length === 0) {
            return -1;
        }
        return Math.max(...filteredFichesTimes);
    }
}

export const ExpositionModule = getModule(MExpo);
