import {IUserData} from '@/entities/user/user.types';
import {IClockInData, IPointageData} from '@/entities/pointage/pointage.types';
import {IEvenementPointageData} from '@/entities/evenement-pointage/evenement-pointage.types';
import {IChantierData} from '@/entities/chantier/chantier.types';
import {ISyncDbAction} from '@/models/application/sync-action';
import {IFicheExpositionData} from '@/entities/fiche-exposition/fiche-exposition.types';
import { IAgenceData } from '@/entities/agence/agence.types';
import { to } from '@/utils';

class MyPyropDb {
    private initialized = false;

    constructor(private dbName: string, private dbVersion: number) {}

    public initialize() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);
            request.onupgradeneeded = evt => {
                const {transaction} = evt.target as any;
                const storeCreateIndex = (
                    objectStore: IDBObjectStore,
                    name: string,
                    options?: IDBIndexParameters
                ) => {
                    if (!objectStore.indexNames.contains(name)) {
                        objectStore.createIndex(name, name, options);
                    }
                };

                let eventPointageStore;
                let chantierStore;
                let clocksinStore;
                
                if (!request.result.objectStoreNames.contains('users')) {
                    request.result.createObjectStore('users', {keyPath: 'id'});
                } else {
                    transaction.objectStore('users');
                }
                if (!request.result.objectStoreNames.contains('pointages')) {
                    request.result.createObjectStore('pointages', {keyPath: 'id'});
                } else {
                    transaction.objectStore('pointages');
                }
                if (!request.result.objectStoreNames.contains('evenements-pointages')) {
                    eventPointageStore = request.result.createObjectStore('evenements-pointages', {
                        keyPath: 'id'
                    });
                } else {
                    eventPointageStore = transaction.objectStore('evenements-pointages');
                }
                if (!request.result.objectStoreNames.contains('chantiers')) {
                    chantierStore = request.result.createObjectStore('chantiers', {keyPath: 'id'});
                } else {
                    chantierStore = transaction.objectStore('chantiers');
                }
                if (!request.result.objectStoreNames.contains('synchro')) {
                    request.result.createObjectStore('synchro', {keyPath: 'order'});
                } else {
                    transaction.objectStore('synchro');
                }
                if (!request.result.objectStoreNames.contains('temp-synchro')) {
                    request.result.createObjectStore('temp-synchro', {keyPath: 'order'});
                } else {
                    transaction.objectStore('temp-synchro');
                }
                if (!request.result.objectStoreNames.contains('fiches-expo')) {
                    request.result.createObjectStore('fiches-expo', {
                        keyPath: 'id'
                    });
                } else {
                    transaction.objectStore('fiches-expo');
                }
                if (!request.result.objectStoreNames.contains('agences')) {
                    request.result.createObjectStore('agences', {keyPath: 'id'});
                } else {
                    transaction.objectStore('agences');
                }
                if (!request.result.objectStoreNames.contains('clocksin')) {
                    clocksinStore = request.result.createObjectStore('clocksin', {keyPath: 'id'});
                } else {
                    clocksinStore = transaction.objectStore('clocksin');
                }

                storeCreateIndex(eventPointageStore, 'idPointage', {unique: false});
                storeCreateIndex(chantierStore, 'name', {
                    unique: false
                });
                storeCreateIndex(clocksinStore, 'userId', {unique: false});
                resolve(undefined);
                this.initialized = true;
            };
            request.onsuccess = () => {
                resolve(undefined);
                this.initialized = true;
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    private getAll<T>(tblName: string): Promise<T[]> {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject(new Error('db_not_ready'));
                return;
            }
            const oRequest = indexedDB.open(this.dbName);
            oRequest.onsuccess = () => {
                const db = oRequest.result;
                const tx = db.transaction(tblName, 'readonly');
                const st = tx.objectStore(tblName);
                const gRequest = st.getAll();
                gRequest.onsuccess = () => {
                    resolve(gRequest.result as T[]);
                };
                gRequest.onerror = () => {
                    reject(gRequest.error);
                };
            };
            oRequest.onerror = () => {
                reject(oRequest.error);
            };
        });
    }

    private set<T>(tblName: string, item: T) {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject(new Error('db_not_ready'));
                return;
            }
            const oRequest = indexedDB.open(this.dbName);
            oRequest.onsuccess = () => {
                const db = oRequest.result;
                const tx = db.transaction(tblName, 'readwrite');
                const st = tx.objectStore(tblName);
                const sRequest = st.put(item);
                sRequest.onsuccess = () => {
                    resolve(undefined);
                };
                sRequest.onerror = () => {
                    reject(sRequest.error);
                };
            };
            oRequest.onerror = () => {
                reject(oRequest.error);
            };
        });
    }

    private setAll<T>(tblName: string, items: T[], empty: boolean) {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject(new Error('db_not_ready'));
                return;
            }
            const oRequest = indexedDB.open(this.dbName);
            oRequest.onsuccess = () => {
                const db = oRequest.result;
                const tx = db.transaction(tblName, 'readwrite');
                const st = tx.objectStore(tblName);
                if (empty) {
                    st.clear();
                }
                items.forEach(item => {
                    st.put(item);
                });
                tx.oncomplete = () => {
                    resolve(undefined);
                };
                tx.onerror = () => {
                    reject(tx.error);
                };
            };
            oRequest.onerror = () => {
                reject(oRequest.error);
            };
        });
    }

    private deleteAll(tblName: string, ids: string[]) {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject(new Error('db_not_ready'));
                return;
            }
            const oRequest = indexedDB.open(this.dbName);
            oRequest.onsuccess = () => {
                const db = oRequest.result;
                const tx = db.transaction(tblName, 'readwrite');
                const st = tx.objectStore(tblName);
                ids.forEach(id => {
                    st.delete(id);
                });
                tx.oncomplete = () => {
                    resolve(undefined);
                };
                tx.onerror = () => {
                    reject(tx.error);
                };
            };
            oRequest.onerror = () => {
                reject(oRequest.error);
            };
        });
    }

    public getUsers(): Promise<IUserData[]> {
        return this.getAll<IUserData>('users');
    }

    public setUser(user: IUserData) {
        return this.set<IUserData>('users', user);
    }

    public deleteUser(id: string) {
        return new Promise((resolve, reject) => {
            const oRequest = indexedDB.open(this.dbName);
            oRequest.onsuccess = () => {
                const db = oRequest.result;
                const tx = db.transaction('users', 'readwrite');
                const st = tx.objectStore('users');
                const rRequest = st.delete(id);
                rRequest.onsuccess = () => {
                    resolve(undefined);
                };
                rRequest.onerror = () => {
                    reject(rRequest.error);
                };
            };
            oRequest.onerror = () => {
                reject(oRequest.error);
            };
        });
    }

    public getPointages(): Promise<IPointageData[]> {
        return this.getAll<IPointageData>('pointages');
    }

    public setPointage(pointage: IPointageData) {
        return this.set<IPointageData>('pointages', pointage);
    }

    public setPointages(pointages: IPointageData[], replaceAll: boolean) {
        this.setAll('pointages', pointages, replaceAll);
    }

    public getEvenementsPointages(): Promise<IEvenementPointageData[]> {
        return this.getAll<IEvenementPointageData>('evenements-pointages');
    }

    public setEvenementPointage(event: IEvenementPointageData) {
        return this.set<IEvenementPointageData>('evenements-pointages', {
            ...event
        });
    }

    public setEvenementsPointages(events: IEvenementPointageData[], replaceAll: boolean) {
        this.setAll('evenements-pointages', events, replaceAll);
    }

    public getChantiers(): Promise<IChantierData[]> {
        return this.getAll<IChantierData>('chantiers');
    }

    public setChantiers(chantiers: IChantierData[]) {
        this.setAll('chantiers', chantiers, true);
    }

    public getSyncData(): Promise<ISyncDbAction[]> {
        return this.getAll<ISyncDbAction>('synchro');
    }

    public getTempSyncData(): Promise<ISyncDbAction[]> {
        return this.getAll<ISyncDbAction>('temp-synchro');
    }

    public setSyncData(data: ISyncDbAction[]) {
        this.setAll('synchro', data, true);
    }

    public setTempSyncData(data: ISyncDbAction[]) {
        this.setAll('temp-synchro', data, true);
    }

    public getFichesExpo(): Promise<IFicheExpositionData[]> {
        return this.getAll<IFicheExpositionData>('fiches-expo');
    }

    public setFichesExpo(fiches: IFicheExpositionData[]) {
        this.setAll('fiches-expo', fiches, false);
    }

    public setFicheExpo(fiche: IFicheExpositionData) {
        this.set('fiches-expo', fiche);
    }

    public getAgences(): Promise<IAgenceData[]> {
        return this.getAll<IAgenceData>('agences');
    }

    public setAgences(agences: IAgenceData[]) {
        this.setAll('agences', agences, true);
    }

    public async getClocksInFor(userId: string | null): Promise<Array<IClockInData>> {
        if (!userId) {
            return Array.of<IClockInData>();
        }
        const [err, allClocksIn] = await to(this.getAll<IClockInData>('clocksin'));
        if (err) {
            console.error(err);
            return Array.of<IClockInData>();
        }
        if (!allClocksIn) {
            return Array.of<IClockInData>();
        }
        return allClocksIn.filter(clockin => clockin.userId === userId);
    }

    public setClocksIn(clocksin: Array<IClockInData>) {
        this.setAll('clocksin', clocksin, false);
    }
    
    public setClockIn(clockIn: IClockInData) {
        return this.set<IClockInData>('clocksin', {
            ...clockIn
        });
    }

    public async clearClocksInFor(userId: string | null) {
        if (!userId) {
            return;
        }
        const [err, allClocksIn] = await to(this.getAll<IClockInData>('clocksin'));
        if (err) {
            console.error(err);
            return;
        }
        if (!allClocksIn) {
            return;
        }
        this.setAll('clocksin', allClocksIn.filter(clockin => clockin.userId !== userId), true);
    }
}

const myDb = new MyPyropDb('my-pyrop', 9);
export default myDb;
