import {Route} from 'vue-router';
import {UserModule} from '@/store/modules/user';
import router from './router';

export function resetPermission() {
    router.beforeEach((to: Route, _: Route, next: any) => {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!UserModule.authenticated) {
                next({
                    path: '/account/login',
                    query: {redirect: to.fullPath}
                });
            } else {
                next();
            }
        } else if (to.matched.some(record => record.meta.requiresAnonymous)) {
            if (UserModule.authenticated) {
                next({path: '/'});
            } else {
                next();
            }
        } else {
            next(); // make sure to always call next()!
        }
    });
}
