/* eslint-disable no-param-reassign */
import Vue from 'vue';
import './registerServiceWorker';
import i18n from '@/i18n';
import store from '@/store';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import Cleave from 'cleave.js';

import App from './App.vue';
import {HTMLElementA} from './types/cleave';

Vue.config.productionTip = false;

navigator.geolocation.getCurrentPosition(
    () => {
        // position pre-acquired
        console.log("position pre-acquired!")
    },
    (err) => {
        // position error
        console.error(err);
    },
    { enableHighAccuracy: true, timeout: 5000, maximumAge:120000 }
);

const ignoredMessage =
    'The .native modifier for v-on is only valid on components but it was used on <div>.';

Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message !== ignoredMessage) {
        // eslint-disable-next-line no-console
        console.error(message + componentTrace);
    }
};

const getInput = (el: HTMLElementA) => {
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
        const els = el.getElementsByTagName('input');
        if (els.length !== 1) {
            throw new Error(`v-cleave requires 1 input, found ${els.length}`);
        } else {
            // eslint-disable-next-line prefer-destructuring
            el = els[0];
        }
    }
    return el;
};

Vue.directive('cleave', {
    inserted: (el: HTMLElementA, binding: any) => {
        el = getInput(el);
        el.cleave = new Cleave(el, binding.value || {});
    },
    update: (el: HTMLElementA) => {
        el = getInput(el);
        const event = new Event('input', {bubbles: true});
        setTimeout(() => {
            el.value = el.cleave?.properties?.result;
            el.dispatchEvent(event);
        }, 100);
    }
});

const vueInstance = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
});

vueInstance.$mount('#app');
