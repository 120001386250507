import i18n from '@/i18n';
import smartPlurals from 'smart-plurals';
import {replacer} from '@/utils';

function nFormatter(num: number, digits: number): string {
    const si = [
        {value: 1, symbol: ''},
        {value: 1e3, symbol: 'k'},
        {value: 1e6, symbol: 'M'},
        {value: 1e9, symbol: 'G'},
        {value: 1e12, symbol: 'T'},
        {value: 1e15, symbol: 'P'},
        {value: 1e18, symbol: 'E'}
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function pluralize(
    text: string,
    amount: number,
    synthesize: boolean,
    digits: number,
    lang: string
): string {
    const arr: string[] = i18n
        .t(text, lang)
        .toString()
        .split(' | ');
    if (arr.length === 1) {
        return '';
    }
    if (amount === 0) {
        return arr[0];
    }
    arr.splice(0, 1);
    const returnText = smartPlurals.Plurals.getRule(lang)(amount, arr);
    return replacer(returnText, {count: synthesize ? nFormatter(amount, digits) : amount});
}

export function pluralForm(text: string, amount: number, lang: string): string {
    const arr: string[] = i18n
        .t(text, lang)
        .toString()
        .split(' | ');
    if (arr.length === 1) {
        return '';
    }
    if (amount === 0) {
        return arr[0];
    }
    arr.splice(0, 1);
    return smartPlurals.Plurals.getRule(lang)(amount, arr);
}
