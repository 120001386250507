export default {
    title: {
        login: 'Connexion à mon compte',
        register: 'Créer mon compte',
        myProfile: 'Mon profil',
        profile: 'Profil',
        forgotPassword: 'Mot de passe oublié ?'
    },
    menu: {
        home: 'Accueil',
        login: 'Se connecter',
        register: "S'enregistrer",
        profile: 'Mon profil',
        logout: 'Se déconnecter',
        guest: 'Invité',
        connect: 'Connectez-vous !',
        settings: 'Configuration',
        pointer: 'Pointer',
        history: 'Historique',
        restaurantMenu: 'Menu',
        restaurantReservations: 'Réservations'
    },
    message: {
        notLogged: 'Vous devez être connecté pour effectuer cette action.',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        close: 'Fermer'
    },
    conges: {
        periodShort: 'Du {start} au {end}',
        congesPayes: 'Congés payés',
        congesSansSolde: 'Congés sans solde',
        Recuperation: 'Récupération',
        absenceNonPayee: 'Absence non payée',
        add: {
            title: 'Demande de congés',
            absTitle: "Demande d'absence",
            form: {
                name: 'Nom de la demande',
                range: 'Période'
            }
        }
    },
    home: {
        anonymous: {
            welcomeText:
                "Veuillez vous connecter à l'aide de votre adresse email professionnelle ou en scannant votre code employé."
        }
    },
    firebase: {
        error: {
            invalid_email: "L'adresse email n'est pas valide.",
            email_already_in_use: "L'adresse email est déjà utilisée.",
            wrong_password: 'Email et/ou mot de passe invalides.',
            user_not_found: "L'adresse email n'est pas enregistrée.",
            network_request_failed: 'Erreur réseau.',
            weak_password: 'Les mot de passe est trop faible.'
        }
    },
    login: {
        title: 'Se connecter',
        email: 'Adresse email',
        password: 'Mot de passe',
        forgotPassword: "J'ai oublié mon mot de passe",
        facebook: 'Facebook',
        google: 'Google',
        loginButton: 'Se connecter',
        register:
            'Pas encore de compte ? ' +
            '<a href="/#/account/register">Enregistre-toi</a> ou ' +
            'connecte-toi grâce à ton compte Facebook ou Google.',
        error: {
            message: 'Impossible de se connecter. Vérifier les erreurs et réessayer.',
            invalidLogin: 'Email et/ou mot de passe invalides.',
            emailRequired: "L'adresse email est requise pour se connecter.",
            invalidEmail: "L'adresse email n'est pas valide.",
            passwordRequired: 'Veuillez saisir votre mot de passe.',
            user_not_preloaded: "Vous n'avez jamais connecté votre utilisateur sur cet appareil."
        },
        success: 'Vous êtes désormais connecté.'
    },
    logout: {
        error: {
            message: 'Impossible de se déconnecter.'
        },
        success: 'Vous êtes désormais déconnecté.'
    },
    password: {
        change: {
            title: 'Changer le mot de passe',
            old: 'Ancien mot de passe',
            new: 'Nouveau mot de passe',
            confirm: 'Confirmer'
        },
        forgot: {
            title: 'Mot de passe oublié ?',
            email: 'Adresse email',
            confirmButton: 'Confirmer',
            success: "Suivez les indications envoyées à l'adresse email indiquée.",
            error: {
                message:
                    'Impossible de réinitialiser le mot de passe. Vérifier les erreurs et réessayer.'
            }
        }
    },
    qrcode: {
        title: 'Scanner un code QR',
        message: {
            alreadyAuthenticated: 'Vous êtes déjà connecté !',
            noCodeDetected: 'Pas de QRCode détecté, merci de ré-essayer.'
        }
    },
    register: {
        title: 'Créer mon compte',
        email: 'Adresse email',
        password: 'Mot de passe',
        registerButton: "S'enregistrer",
        success: 'Votre compte a été créé. Vous pouvez maintenant vous connecter.',
        error: {
            message: "Votre compte n'a pas été créé, veuillez corriger les erreurs et réessayer.",
            InvalidEmail: "L'adresse email n'est pas valide.",
            DuplicateEmail:
                "L'adresse email a déjà été utilisée. " +
                'Si vous possédez un compte, vous pouvez vous connecter normalement.',
            PasswordTooShort: 'Le mot de passe est trop court.',
            PasswordRequiresNonAlphanumeric: 'Le mot de passe nécessite des caractères spéciaux.',
            PasswordRequiresDigit: 'Le mot de passe nécessite des chiffres.',
            PasswordRequiresUpper: 'Le mot de passe nécessite des majuscules.',
            PasswordRequiresLower: 'Le mot de passe nécessite des minuscules.',
            PasswordRequiresUniqueChars: 'Le mot de passe nécessite au moins 3 caractères uniques.',
            cannot_register_offline: 'Vous ne pouvez pas créer de compte en mode hors ligne.'
        },
        login:
            'Déjà un compte ou tu souhaites te connecter grâce à Facebook ou Google ? ' +
            '<a href="/#/account/login">Clique ici</a>.'
    },
    pointage: {
        title: 'Pointages',
        startButton: 'Pointer',
        stopButton: 'Terminer',
        enterZoneButton: 'Je rentre sur un chantier amiante ou plomb',
        exitZoneButton: 'Ma session de travail',
        zoneTitle: 'Zone',
        geolocationPositionError: [
            'Géolocalisation non fonctionnelle.',
            "Vous n'avez pas autorisé la géolocalisation sur l'application, Il n'est pas possible de pointer sans activer la géolocalisation car elle permet de vous relier à un chantier.",
            'Il semblerait que votre appareil ait un problème pour obtenir la géolocalisation, vous ne pouvez donc pas pointer.',
            "Il semblerait que votre appareil n'a pas pu vous géolocaliser assez rapidement, veuillez ré-essayer..."
        ],
        errorTitle: "Une erreur s'est produite.",
        message: {
            geolocationAdvice: 'Pensez à autoriser la géolocalisation sur votre appareil.',
            isNotClocking: "Débuter un nouveau pointage.",
            clockingTime:
                'Vous venez tout juste de pointer. | Vous avez pointé il y a 1 minute | Vous avez pointé il y a $(hours) heures et $(minutes) minutes'
        }
    },
    agentsChimiques: ['Chrysotile', 'Amosite', 'Mélange', 'Plomb', 'Autre'],
    demandeHorsDelais: ['Inf. à 14 jours', 'Inf. à 30 jours', 'Inf. à 60 jours', ''],
    etatAbsence: ['Demande initiale', 'Vérification RH', 'Validée', 'Refusée', 'Annulée'],
    transportMode: ['-', '-', 'Propres moyens', 'Société'],
    etatAcompte: [
        'Demande initiale',
        'En cours de vérification',
        'En cours de traitement',
        'Versé',
        'Déduit salaire',
        'Annulé',
        'Refusé'
    ],
    absenceSubType: [
        'Congés payés',
        'Congés sans solde',
        'Absence autorisée',
        'Absence non autorisée',
        'Maladie',
        'Accident du travail',
        'Chômage partiel',
        'Formation',
        'Maternité',
        'Paternité',
        'Naissance (3j)',
        'Mariage - PACS (4j)',
        "Mariage d'un enfant (1j)",
        "Décès d'un proche (1-3j)",
        'Déménagement',
        'Convocation officielle (administration)',
        'Absence sanction disciplinaire (mise à pieds)',
        'Autre',
        'Révisions',
        'Récupération chauffeurs',
        'Compte épargne-temps (CET)'
    ],
    masque: [
        'Aucun masque',
        '½ Masque (M)',
        'Masque à adduction d’air (MAA)',
        'Masque à ventilation assistée (MVA)'
    ],
    epis: [
        'Combinaison (C)',
        'Gants (G)',
        'Bottes (B)',
        'Sous vêtement (SV)',
        'Double combinaison',
        'Sur-bottes',
        'Lunettes',
        'Casque',
        'Harnais',
        'Casque anti-bruit'
    ],
    activites: [
        'Confinement (CF)',
        'Repli de zone (RP)',
        'Aspiration (A)',
        'Dépoussiérage (D)',
        'Nettoyage fin (NF)',
        'Retrait (RT)',
        'Evacuation des déchets',
        'Installation Générale',
        'Repli Général'
    ]
};
