














































































import {Vue, Component} from 'vue-property-decorator';
import VueCountdown from '@chenfengyuan/vue-countdown';
import SvgIcon from '@/components/SvgIcon.vue';
import {ExpositionModule} from '../../store/modules/exposition';

@Component({
    components: {SvgIcon, VueCountdown}
})
export default class Step5Component extends Vue {
    private now = new Date();

    private get computedTempsRestant() {
        return ExpositionModule.ficheEnCours?.getTempsRecuperationEnSeconde(this.now) || 0;
    }

    private goToNextStep() {
        this.$emit('gotonext');
    }

    private countdownProgress() {
        this.now = new Date();
    }

    private countdownEnd() {
        this.now = new Date();
    }
}
