


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from './SvgIcon.vue';

@Component({
    components: {
        SvgIcon
    }
})
export default class SvgToggleButton extends Vue {
    @Prop({ type: String, required: false, default: () => 'epi' })
    private iconCategory!: string;

    @Prop({ type: String, required: true })
    private iconName!: string;

    @Prop({
        type: String,
        required: false
    })
    private primary!: string;

    @Prop({
        type: String,
        required: false
    })
    private secondary!: string;

    @Prop({ type: String, required: false, default: () => 'auto' })
    private width!: string;

    @Prop({ type: String, required: false, default: () => 'auto' })
    private height!: string;

    @Prop({ type: Boolean, required: true })
    private checked!: boolean;

    private bChecked = false;

    @Watch('checked')
    private onCheckedChange() {
        if (this.bChecked !== this.checked) {
            this.bChecked = this.checked;
        }
    }

    private mounted() {
        this.$nextTick(() => {
            this.bChecked = this.checked;
        });
    }

    private get computedPrimaryColor() {
        return this.primary || this.$vuetify.theme.themes.light.primary;
    }

    private get computedSecondaryColor() {
        return this.secondary || this.$vuetify.theme.themes.light.secondary;
    }

    private get computedButtonClass() {
        return {
            '--color-1': !this.bChecked ? this.computedPrimaryColor : this.computedSecondaryColor,
            '--color-2': '#ffffff'
        };
    }

    private handleToggleButton() {
        this.bChecked = !this.bChecked;
        this.$emit('update:checked', !this.checked);
    }
}
