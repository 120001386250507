

















































import {Component, Vue} from 'vue-property-decorator';
import eventBus from '@/eventBus';
import IllustrationNoContent from '@/components/Illustrations/NoContent.vue';
import {UserModule} from '@/store/modules/user';
import { NotificationMessage } from '@/entities/user/user.types';

@Component({
    name: 'NotificationsList',
    components: {IllustrationNoContent},
})
export default class extends Vue {
    public showDetails = false;
    public selectedMessage: NotificationMessage | null = null;

    public mounted() {
        eventBus.$on('notification', this.liveNotification);
    }

    public async beforeUnmount() {
        eventBus.$off('notification');
    }

    public get messages(): Array<NotificationMessage> {
        return UserModule.notifications;
    }

    public get messageCount() {
        return this.messages.length;
    }

    public get hasNotifications() {
        return this.messageCount > 0;
    }

    public readDetails(id: string) {
        const message = this.messages.find(msg => msg.id === id);
        if (!message) {
            return;
        }
        if (message.messageType === 'Birthday') {
            eventBus.$emit('confetti');
        }
        this.selectedMessage = message;
        this.showDetails = true;

        UserModule.readNotification(this.selectedMessage);

        eventBus.$emit('notify-server', JSON.stringify({
            action: 'read',
            userId: UserModule.id,
            notificationId: this.selectedMessage.id
        }));
    }

    public liveNotification(notifJsonStr: string) {
        const notif: NotificationMessage = JSON.parse(notifJsonStr);

        UserModule.addNotification(notif);
        this.readDetails(notif.id);
    }

    public closeAndDisposeMessage() {
        if (!this.selectedMessage) {
            return;
        }

        this.showDetails = false;
        this.selectedMessage = null;
    }
}
