import {Store} from 'vuex';
import {IRootState} from '@/store/';
import {requestSyncData} from '@/api/data';
import {to, reviveUTCDate, isToday} from '@/utils';
import {IDataSyncResult} from '@/models/viewmodels/data/IDataSyncResult';
// import {SyncModule} from '@/store/modules/sync';

export default function createSyncDataPlugin() {
    return (store: Store<IRootState>) => {
        let syncIntervalId = -1;

        const syncData = () => {
            if (!store.state.user.token) {
                // eslint-disable-next-line no-console
                console.log('User not authenticated, cannot sync data.');
                return;
            }

            if (store.hasModule('synchro')) {
                const hasSyncFinished: boolean = store.getters['synchro/syncFinished'];
                if (!hasSyncFinished) {
                    store.dispatch('synchro/syncData');
                }
            }
        };

        const forceRefreshData = async () => {
            if (!store.state.user.token) {
                // eslint-disable-next-line no-console
                console.log('User not authenticated, cannot sync data.');
                return;
            }

            const [, data] = await to<IDataSyncResult>(requestSyncData());
            if (data) {
                if (
                    store.hasModule('agence') &&
                    data.agences
                ) {
                    store.dispatch('agence/serverAgenceDataUpdate', data.agences);
                }
                if (
                    store.hasModule('chantier') &&
                    data.chantiers &&
                    Array.isArray(data.chantiers) &&
                    data.chantiers.length > 0
                ) {
                    store.dispatch('chantier/serverChantierDataUpdate', data.chantiers);
                }
                if (store.hasModule('pointage')) {
                    if (data.user.cleansBefore) {
                        const revivedDate = reviveUTCDate(data.user.cleansBefore);
                        if (revivedDate < new Date() && !isToday(revivedDate)) {
                            store.dispatch('pointage/cleansBefore', revivedDate);
                        }
                    }
                    // if (
                    //     data.pointages &&
                    //     Array.isArray(data.pointages) &&
                    //     data.pointages.length > 0
                    // ) {
                    //     store.dispatch('pointage/serverPointageDataUpdate', data.pointages);
                    // }
                    // if (data.events && Array.isArray(data.events) && data.events.length > 0) {
                    //     store.dispatch('pointage/serverEventsDataUpdate', data.events);
                    // }
                    if (data.runningPointage) {
                        store.dispatch('pointage/serverPointageDataUpdate', data.runningPointage);
                    }
                    if (data.todayClockIn) {
                        store.dispatch('pointage/serverClockInDayDataUpdate', data.todayClockIn);
                    }
                }
                if (store.hasModule('user')) {
                    if (data.user && data.user.lastSyncDate) {
                        store.dispatch('user/serverUserDataUpdate', data.user);
                    }
                    store.dispatch('user/addNotifications', data.notifications);
                }
                if (
                    store.hasModule('exposition') &&
                    data.fichesExposition &&
                    Array.isArray(data.fichesExposition) &&
                    data.fichesExposition.length > 0
                ) {
                    store.dispatch('exposition/serverFichesExpoDataUpdate', data.fichesExposition);
                }
            }
        };

        store.subscribe(mutation => {
            if (mutation.type === 'application/SET_ONLINE') {
                if (mutation.payload && syncIntervalId === -1) {
                    syncIntervalId = window.setInterval(syncData, 30000);
                } else if (!mutation.payload && syncIntervalId !== -1) {
                    clearInterval(syncIntervalId);
                }
            }
            if (mutation.type === 'user/SET_TOKEN' && mutation.payload) {
                forceRefreshData().then(
                    () => syncData()
                );
            }
            if (mutation.type === 'application/FORCE_REFRESH') {
                forceRefreshData();
            }
            if (mutation.type === 'application/FORCE_SYNC') {
                syncData();
            }
        });
    };
}
