import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {ISyncAction, ISyncActionType} from '@/models/application/sync-action';
import {reviveUTCDate, to} from '@/utils';
import {postSyncData} from '@/api/data';

export interface ISyncState {
    sActions: Array<ISyncAction>;
    sTempActions: Array<ISyncAction>;
    initialized: boolean;
    inProgress: boolean;
}

@Module({dynamic: true, store, name: 'synchro', namespaced: true})
class MSync extends VuexModule implements ISyncState {
    public sActions: Array<ISyncAction> = [];
    public sTempActions: Array<ISyncAction> = [];
    public initialized = false;
    public inProgress = false;

    @Mutation
    private SET_SACTIONS(sActions: Array<ISyncAction>) {
        this.sActions = sActions;
    }

    @Mutation
    private SET_STEMPACTIONS(sTempActions: Array<ISyncAction>) {
        this.sTempActions = sTempActions;
    }

    @Mutation
    private SET_INITIALIZED(initialized: boolean) {
        this.initialized = initialized;
    }

    @Mutation
    private SET_INPROGRESS(inProgress: boolean) {
        this.inProgress = inProgress;
    }

    @Action
    public addSyncAction(sAction: {aType: ISyncActionType; payload: any}) {
        const newSAction: ISyncAction = {
            at: new Date(),
            actionType: sAction.aType,
            payload: sAction.payload
        };
        
        if (!this.initialized) {
            const finalArray = [...this.sTempActions, newSAction];
            this.SET_STEMPACTIONS(finalArray);
            return;
        }
        if (!this.inProgress) {
            const finalArray = [...this.sActions, newSAction];
            myPyropDb.setSyncData(
                finalArray.map((value, index) => {
                    return {
                        ...value,
                        order: index
                    };
                })
            );
            this.SET_SACTIONS(finalArray);
        } else {
            const finalArray = [...this.sTempActions, newSAction];
            myPyropDb.setTempSyncData(
                finalArray.map((value, index) => {
                    return {
                        ...value,
                        order: index
                    };
                })
            );
            this.SET_STEMPACTIONS(finalArray);
        }
    }

    @Action
    public async syncData() {
        if (this.syncFinished || this.inProgress) {
            return;
        }

        this.SET_INPROGRESS(true);

        const nActionsArr = cloneDeep(this.sActions);
        const countMax = nActionsArr.length < 50 ? nActionsArr.length : 50;
        let current: ISyncAction | undefined | null = null;
        let i = 0;
        while (i < countMax) {
            current = nActionsArr.shift();
            if (!current) {
                break;
            }

            // eslint-disable-next-line no-await-in-loop
            const [, data] = await to(postSyncData(current));

            if (!data || !data.status || data.status !== 'OK') {
                // if we got an error during the sync operation,
                // we requeue the element and break the sync process.
                nActionsArr.splice(0, 0, current);
                break;
            }

            i++;
        }

        const finalArray = nActionsArr.concat(cloneDeep(this.sTempActions));

        this.SET_SACTIONS(finalArray);
        myPyropDb.setSyncData(
            finalArray.map((value, index) => {
                return {
                    ...value,
                    order: index
                };
            })
        );
        this.SET_STEMPACTIONS([]);
        myPyropDb.setTempSyncData([]);

        this.SET_INPROGRESS(false);
    }

    @Action
    public async initializeStore() {
        const [, data] = await to(myPyropDb.getSyncData());
        const [, tempData] = await to(myPyropDb.getTempSyncData());

        if (data && Array.isArray(data) && data.length > 0) {
            const sActions: Array<ISyncAction> = data.sort(
                (sdataA, sdataB) => reviveUTCDate(sdataA.at).getTime() - reviveUTCDate(sdataB.at).getTime()
            );
            this.SET_SACTIONS(sActions);
        }
        if (tempData && Array.isArray(tempData) && tempData.length > 0) {
            const sActions: Array<ISyncAction> = tempData.sort(
                (sdataA, sdataB) => reviveUTCDate(sdataA.at).getTime() - reviveUTCDate(sdataB.at).getTime()
            );
            const finalArray = this.sActions.concat(sActions);
            this.SET_SACTIONS(finalArray);
        }
        if (this.sTempActions.length > 0) {
            const finalArray = this.sActions.concat(this.sTempActions);
            myPyropDb.setSyncData(
                finalArray.map((value, index) => {
                    return {
                        ...value,
                        order: index
                    };
                })
            );
            this.SET_SACTIONS(finalArray);
            myPyropDb.setTempSyncData([]);
            this.SET_STEMPACTIONS([]);
        }

        this.SET_INITIALIZED(true);
    }

    public get syncFinished() {
        return this.sActions.length === 0;
    }

    public get waitCount() {
        const queueLength = this.sActions.length;
        return queueLength > 9 ? '9+' : queueLength.toFixed(0);
    }
}

export const SyncModule = getModule(MSync);
