




















import {Vue, Component} from 'vue-property-decorator';
import UserTimeTrackerComponent from '@/components/User/TimeTracker.vue';
import ZoneTimeTracker from '@/components/User/ZoneTimeTracker.vue';
import UserClockinDayComponent from '@/components/User/ClockinDay.vue';
import { IClockInData } from '@/entities/pointage/pointage.types';
import { PointageModule } from '@/store/modules/pointage';

@Component({
    components: {
        UserTimeTrackerComponent,
        ZoneTimeTracker,
        UserClockinDayComponent
    }
})
export default class LoggedHome extends Vue {
    
    public get pointageEnCours(): IClockInData | undefined {
        return PointageModule.currentClockInData;
    }

    public get isWorkingRemotely(): boolean {
        return this.pointageEnCours?.isWorkingRemotely ?? false;
    }
}
