import {MaskType} from '@/entities/fiche-exposition/fiche-exposition.types';

/* eslint-disable-next-line no-shadow */
export enum ChangeEntrerEnZoneRequestType {
    // tslint:disable:no-bitwise
    None = 0,
    Masque = 1 << 0,
    PossedeMasque = 1 << 1,
    TauxEmpoussierement = 1 << 2,
    Temperature = 1 << 3,
    EPI = 1 << 4,
    Activite = 1 << 5,
    AgentsChimiques = 1 << 6,
    AutreAgentsChimiques = 1 << 7,
    EntrerAvecMasque = 1 << 26,
    SortirDeZone = 1 << 27,
    ValiderInformations = 1 << 28,
    SignerFiche = 1 << 29,
    Creer = 1 << 30,
    Annuler = 1 << 31
}

export class EntrerEnZoneRequestViewModel {
    public changes: ChangeEntrerEnZoneRequestType = ChangeEntrerEnZoneRequestType.None;

    public id!: string;

    public masque!: MaskType;

    public possedeMasque!: boolean;

    public tauxEmpoussierement?: number;

    public temperature?: number;

    public epis!: number;

    public activites!: number;

    public agentsChimiques!: number;

    public autresAgentsChimiques: string[] = [];

    public signature!: string;
}
