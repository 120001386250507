import Vue from 'vue';
import VueI18n from 'vue-i18n';

import fr from '@/locales/fr.msg';

const messages = {
    fr
};

const dateTimeFormats: VueI18n.DateTimeFormats = {
    fr: {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        },
        weekdayOnly: {
            weekday: 'long'
        },
        weekdayStart: {
            weekday: 'long',
            day: 'numeric'
        },
        weekdayStartFull: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        },
        weekdayStartMonth: {
            weekday: 'long',
            day: 'numeric',
            month: 'long'
        },
        weekdayEnd: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        },
        hour24: {
            hour: '2-digit',
            minute: '2-digit'
        }
    }
};

Vue.use(VueI18n);

const localeSetting = () => {
    const language = localStorage.getItem('user-locale') || window.navigator.language;
    switch (language) {
        case 'fr-FR':
            return 'fr';
        default:
            return 'fr';
    }
};

// Create VueI18n instance with options
export default new VueI18n({
    locale: localeSetting(), // set locale
    fallbackLocale: 'fr-FR',
    messages, // set locale messages
    dateTimeFormats
});
