




















import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import SignaturePad from 'signature_pad/src/signature_pad';
import {shortGuid} from '@/utils';

@Component({})
export default class VueSignature extends Vue {
    @Prop({
        type: Object,
        default: () => {
            return {
                backgroundColor: 'rgb(255,255,255)',
                penColor: 'rgb(0, 0, 0)'
            };
        }
    })
    private sigOption!: any;

    @Prop({type: String, default: '100%'})
    private w!: string;

    @Prop({type: String, default: '100%'})
    private h!: string;

    @Prop({type: Boolean, default: false})
    private clearOnResize!: boolean;

    @Prop({type: Boolean, default: false})
    private allowPortrait!: boolean;

    @Prop({
        type: Object,
        default: () => {
            return {};
        }
    })
    private waterMark!: any;

    @Prop({type: Boolean, default: false})
    private disabled!: boolean;

    @Prop({type: String, default: ''})
    private defaultUrl!: string;

    private sig: SignaturePad = {} as SignaturePad;

    private canvas!: HTMLCanvasElement;

    private option: any = {
        backgroundColor: 'rgb(255,255,255)',
        penColor: 'rgb(0, 0, 0)'
    };

    private uid = '';

    private imgData = '';
    private signCleared = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private resizeCanvas = () => {};

    @Watch('disabled')
    private onDisabledChanged(value: boolean) {
        if (value) {
            this.sig.off();
        } else {
            this.sig.on();
        }
    }

    @Watch('$vuetify.breakpoint.smAndUp')
    private onIsSmAndUpChanged(value: boolean) {
        if (value && !this.allowPortrait) {
            this.imgData = '';
            this.$nextTick().then(() => {
                this.draw();
            });
        } else {
            this.imgData = this.save(undefined);
        }
    }

    private get computedDivStyle() {
        return [
            `width:${this.w}`,
            `height:${this.h}`,
            'max-width:500px',
            'max-height:150px;',
            'position: relative;'
        ].join(';');
    }

    private mounted() {
        this.resizeCanvas = () => {
            if (!this.canvas) {
                return;
            }

            let url;
            if (!this.isEmpty) {
                url = this.save(undefined);
            }
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            this.canvas.width = this.canvas.offsetWidth * ratio;
            this.canvas.height = this.canvas.offsetHeight * ratio;

            const canvasCtx = this.canvas.getContext('2d');

            if (!canvasCtx) {
                return;
            }

            canvasCtx.scale(ratio, ratio);
            this.clear();
            // eslint-disable-next-line no-unused-expressions
            !this.clearOnResize && url !== undefined && this.fromDataURL(url);
            // eslint-disable-next-line no-unused-expressions
            Object.keys(this.waterMark).length && this.addWaterMark(this.waterMark);
        };
        this.$nextTick().then(() => {
            this.draw();
            window.addEventListener('resize', this.resizeCanvas.bind(this));
        });
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.resizeCanvas);
        if (this.sig && this.sig.off) {
            this.sig.off();
        }
    }

    private created() {
        this.uid = `canvas${shortGuid()}`;
        Object.keys(this.sigOption).forEach(key => {
            this.option[key] = this.sigOption[key];
        });
    }

    private async draw() {
        this.canvas = document.getElementById(this.uid) as HTMLCanvasElement;
        if (!this.canvas) {
            return;
        }

        this.sig = new SignaturePad(this.canvas, this.option);
        this.resizeCanvas();
        if (this.defaultUrl !== '') {
            this.fromDataURL(this.defaultUrl);
        }
    }

    public clear() {
        this.signCleared = true;
        this.sig.clear();
    }

    public save(format: string | undefined) {
        return format ? this.sig.toDataURL(format) : this.sig.toDataURL();
    }

    public fromDataURL(url: string) {
        this.sig.fromDataURL(url);
    }

    public undo() {
        const data = this.sig.toData();
        if (data) {
            data.pop();
            this.sig.fromData(data);
        }
    }

    public addWaterMark(data: any) {
        if (!(Object.prototype.toString.call(data) === '[object Object]')) {
            throw new Error(`Expected Object, got ${typeof data}.`);
        } else {
            if (!this.canvas) {
                const vCanvas = document.getElementById(this.uid) as HTMLCanvasElement;
                if (!vCanvas) {
                    return;
                }
                this.canvas = vCanvas;
            }

            const textData = {
                text: data.text || '',
                x: data.x || 20,
                y: data.y || 20,
                sx: data.sx || 40,
                sy: data.sy || 40
            };

            const ctx = this.canvas.getContext('2d');
            if (ctx === null) {
                return;
            }
            ctx.font = data.font || '15px sans-serif';
            ctx.fillStyle = data.fillStyle || '#333';
            ctx.strokeStyle = data.strokeStyle || '#333';
            if (data.style === 'all') {
                ctx.fillText(textData.text, textData.x, textData.y);
                ctx.strokeText(textData.text, textData.sx, textData.sx);
            } else if (data.style === 'stroke') {
                ctx.strokeText(textData.text, textData.sx, textData.sx);
            } else {
                ctx.fillText(textData.text, textData.x, textData.y);
            }
            // this.sig._isEmpty = false; // Cannot set private member
        }
    }

    public get isEmpty() {
        if (this.sig && this.sig.isEmpty) {
            return this.sig.isEmpty();
        }
        return true;
    }
}
