












import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class SvgIcon extends Vue {
    @Prop({type: String, required: false, default: () => 'epi'})
    private iconCategory!: string;

    @Prop({type: String, required: true})
    private iconName!: string;

    @Prop({type: String, required: false})
    private fill!: string;

    @Prop({type: String, required: false, default: () => 'auto'})
    private width!: string;

    @Prop({type: String, required: false, default: () => 'auto'})
    private height!: string;

    @Prop({type: Boolean, required: false, default: () => false})
    private extended!: boolean;

    @Prop({type: String, required: false, default: () => ''})
    private svgStyles!: string;

    private get computedImg() {
        if (!this.extended) {
            // eslint-disable-next-line global-require
            return require(`@/assets/icons/${this.iconName}.svg`);
        }
        // eslint-disable-next-line global-require
        return `${require(`@/assets/icons/icons-${this.iconCategory}.svg`)}#${this.iconName}`;
    }

    private get computedWidth() {
        return this.width || '';
    }

    private get computedHeight() {
        return this.height || '';
    }

    private get computedStyles() {
        const styles = [`height: ${this.height}`, `width: ${this.width}`];

        if (!this.extended) {
            styles.push(
                `-webkit-mask: url(${this.computedImg}) no-repeat center`,
                `mask: url(${this.computedImg}) no-repeat center`
            );

            if (this.fill) {
                styles.push(`background-color:${this.fill}`);
            }
        }

        return styles.join(';');
    }
}
