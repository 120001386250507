import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

// Static container
import RouterContainer from '@/components/RouterContainer.vue';

import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/qrcode',
        name: 'QRCode',
        component: () => import(/* webpackChunkName: "qrcode" */ '@/views/QRCode.vue')
    },
    {
        path: '/account',
        name: 'anonymous',
        components: {
            default: RouterContainer,
            navigation: () =>
                import(
                    /* webpackChunkName: "anonymousAccountToolbar" */ '@/components/Toolbars/AnonymousAccountToolbar.vue'
                )
        },
        props: {
            default: false, // <-- params will be sent to RouterContainer & not to child component
            navigation: true,
            extra: true
        },
        children: [
            {
                path: 'login/:qrcodeLogin?',
                name: 'login',
                component: () =>
                    import(/* webpackChunkName: "accountLogin" */ '@/views/Account/Login.vue'),
                meta: {
                    navigationIsDense: true,
                    title: 'title.login',
                    requiresAnonymous: true
                },
                props: true // <-- We want params to be sent to this component
            },
            {
                path: 'register',
                name: 'register',
                component: () =>
                    import(
                        /* webpackChunkName: "accountRegister" */ '@/views/Account/Register.vue'
                    ),
                meta: {
                    navigationIsDense: true,
                    title: 'title.register',
                    requiresAnonymous: true
                }
            },
            {
                path: 'forgotPassword',
                name: 'forgotPassword',
                component: () =>
                    import(
                        /* webpackChunkName: "forgotPassword" */ '@/views/Account/ForgotPassword.vue'
                    ),
                meta: {
                    requiresAnonymous: true,
                    title: 'title.forgotPassword'
                }
            }
        ]
    },
    {
        path: '/user',
        name: 'user',
        components: {
            default: RouterContainer,
            navigation: () =>
                import(
                    /* webpackChunkName: "emptyToolbar" */ '@/components/Toolbars/EmptyToolbar.vue'
                )
        },
        children: [
            {
                path: 'profile',
                name: 'profile',
                component: () =>
                    import(
                        /* webpackChunkName: "userProfile" */ '@/views/User/Profile.vue'
                    ),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'planning',
                name: 'planning',
                component: () =>
                    import(
                        /* webpackChunkName: "notYetAvailable" */ '@/views/NotYetAvailable.vue'
                    ),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'absences',
                name: 'absences',
                component: () =>
                    import(/* webpackChunkName: "userAbsences" */ '@/views/User/Absences.vue'),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'acompte',
                name: 'acompte',
                component: () =>
                    import(
                        /* webpackChunkName: "notYetAvailable" */ '@/views/User/Acompte.vue'
                    ),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'fiche-expo',
                name: 'fiche-expo',
                component: () =>
                    import(
                        /* webpackChunkName: "fichesExpositions" */ '@/views/User/Expositions.vue'
                    ),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'expo-accidentelle',
                name: 'expo-accidentelle',
                component: () =>
                    import(
                        /* webpackChunkName: "expoAccidentelle" */ '@/views/User/ExpoAccidentelle.vue'
                    ),
                meta: {disableNavigation: true, requiresAuth: true}
            },
            {
                path: 'refresh-data',
                name: 'refresh-data',
                component: () =>
                    import(/* webpackChunkName: "refreshData" */ '@/views/User/Sync.vue'),
                meta: {disableNavigation: true, requiresAuth: true}
            }
        ]
    },
    {
        path: '/user',
        name: 'user-pointages',
        components: {
            default: RouterContainer,
            navigation: () =>
                import(
                    /* webpackChunkName: "pointageToolbar" */ '@/components/Toolbars/UserPointageToolbar.vue'
                )
        },
        children: [
            {
                path: 'pointer',
                name: 'pointer',
                component: () =>
                    import(/* webpackChunkName: "userPointer" */ '@/views/User/Pointer.vue'),
                meta: {disableNavigation: false, requiresAuth: true}
            },
            {
                path: 'pointages',
                name: 'pointages',
                component: () =>
                    import(/* webpackChunkName: "userPointages" */ '@/views/User/Pointages.vue'),
                meta: {disableNavigation: false, requiresAuth: true}
            }
        ]
    },
    {
        path: '/restaurant',
        name: 'restaurant',
        components: {
            default: RouterContainer,
            navigation: () =>
                import(
                    /* webpackChunkName: "anonymousAccountToolbar" */ '@/components/Toolbars/RestaurantToolbar.vue'
                )
        },
        children: [
            {
                path: 'menu',
                name: 'menu',
                component: () =>
                    import(/* webpackChunkName: "menuRestaurant" */ '@/views/Restaurant/Menu.vue'),
                meta: {disableNavigation: false, requiresAuth: false}
            },
            {
                path: 'update-menu',
                name: 'update-menu',
                component: () =>
                    import(
                        /* webpackChunkName: "menuRestaurantUpdate" */ '@/views/Restaurant/UpdateMenu.vue'
                    ),
                meta: {disableNavigation: false, requiresAuth: true}
            },
            {
                path: 'reservations',
                name: 'reservations',
                component: () =>
                    import(
                        /* webpackChunkName: "reservationsRestaurant" */ '@/views/Restaurant/Inscription.vue'
                    ),
                meta: {disableNavigation: false, requiresAuth: true}
            }
        ]
    },
    
    {
        path: '/aide',
        name: 'aide',
        components: {
            default: RouterContainer,
            navigation: () =>
                import(
                    /* webpackChunkName: "emptyToolbar" */ '@/components/Toolbars/EmptyToolbar.vue'
                )
        },
        children: [
            {
                path: 'compte',
                name: 'compte',
                component: () =>
                    import(
                        /* webpackChunkName: "aideCompte" */ '@/views/Aide/AideCompteMyPyrop.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'restaurant',
                name: 'restaurantHelp',
                component: () =>
                    import(
                        /* webpackChunkName: "aideRestaurant" */ '@/views/Aide/AideRestaurant.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'conges',
                name: 'conges',
                component: () =>
                    import(
                        /* webpackChunkName: "aideConges" */ '@/views/Aide/AideConges.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'pointage',
                name: 'pointage',
                component: () =>
                    import(
                        /* webpackChunkName: "aidePointage" */ '@/views/Aide/AidePointage.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'page1',
                name: 'page1',
                component: () =>
                    import(
                        /* webpackChunkName: "aidePage1" */ '@/views/Aide/AidePage1.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'journal',
                name: 'journal',
                component: () =>
                    import(
                        /* webpackChunkName: "journal" */ '@/views/Aide/Journal.vue'
                    ),
                meta: {disableNavigation: true}
            },
            {
                path: 'view/:pdfUrl?',
                name: 'view-pdf',
                component: () =>
                    import(/* webpackChunkName: "pdfViewer" */ '@/components/Aide/PdfViewer.vue'),
                meta: {disableNavigation: true},
                props: true // <-- We want params to be sent to this component
            },
        ]
    },
];

const createRouter = () =>
    new VueRouter({
        mode: 'history',
        scrollBehavior: (to, from, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            }
            return {x: 0, y: 0};
        },
        base: process.env.BASE_URL,
        routes
    });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
