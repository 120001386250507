import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import {IUserState} from './modules/user';
import {IApplicationState} from './modules/application';
import {IPointageState} from './modules/pointage';
import {IChantierState} from './modules/chantier';
import {ISyncState} from './modules/synchro';
import {IExpoState} from './modules/exposition';
import {IAbsenceState} from './modules/absence';
// import createSyncDataPlugin from './plugins/sync-plugin';

Vue.use(Vuex);

const debug = process.env.VUE_APP_ENV !== 'production';

export interface IRootState {
    application: IApplicationState;
    user: IUserState;
    pointage: IPointageState;
    chantier: IChantierState;
    synchro: ISyncState;
    fiche: IExpoState;
    absence: IAbsenceState;
}

export default new Vuex.Store<IRootState>({
    // strict: debug,
    plugins: debug ? [createLogger()] : []
});
