// eslint-disable-next-line @typescript-eslint/no-unused-vars
import UAParser from 'ua-parser-js';
import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import cloneDeep from 'lodash/cloneDeep';
import {
    ClockInDayPayedCompensationType,
    IClockInData,
    IClockInDayData,
    PointageDetectionMode,
    PointageStatus,
} from '@/entities/pointage/pointage.types';
import {
    ClockInEventData,
    PointageEventType,
} from '@/entities/evenement-pointage/evenement-pointage.types';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {isToday, shortGuid, to, reviveUTCDate, getLocation} from '@/utils';
import {detectChantier} from '@/utils/chantiers';
import {ISyncActionType} from '@/models/application/sync-action';
import {ClockingChangeType} from '@/models/viewmodels/user/ClockingExtraProperty';
import i18n from '@/i18n';

import {ApplicationModule} from './application';
import {SyncModule} from './synchro';
import {ExpositionModule} from './exposition';
import {UserModule} from './user';
import {ChantierModule} from './chantier';
import {Chantier} from '@/entities/chantier/chantier';

export interface IPointageState {
    clockingId: string | null;
    initialized: boolean;
    inAction: boolean;
    clocksin: Array<IClockInData>;
    todayClockIn: IClockInDayData | null;
}

@Module({dynamic: true, store, name: 'pointage', namespaced: true})
class MPointage extends VuexModule implements IPointageState {
    public clockingId: string | null = null;
    public initialized = false;
    public inAction = false;
    public clocksin: Array<IClockInData> = [];
    public todayClockIn: IClockInDayData | null = null;

    @Mutation
    private SET_CLOCKINGID(id: string | null) {
        this.clockingId = id;
    }

    @Mutation
    private SET_INITIALIZED(initialized: boolean) {
        this.initialized = initialized;
    }

    @Mutation
    private SET_IN_ACTION(action: boolean) {
        this.inAction = action;
    }

    @Mutation
    private SET_CLOCKSIN(clocksin: Array<IClockInData>) {
        this.clocksin = clocksin;
    }

    @Mutation
    private SET_CLOCKINDAY(clocksinday: IClockInDayData) {
        this.todayClockIn = clocksinday;
    }

    @Mutation
    private SET_CLOCKIN(clockin: IClockInData) {
        const clocksin = [...this.clocksin];
        const pIndex = clocksin.findIndex((p) => p.id === clockin.id);
        if (pIndex !== -1) {
            clocksin.splice(pIndex, 1);
        }
        clocksin.push(clockin);
        this.clocksin = clocksin;
    }

    @Action
    public async startStopPointage() {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000,
            });
            return;
        }
        if (this.inAction) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Vous avez déjà cliqué sur le bouton, merci de patienter !',
                timeout: 10000,
            });
            return;
        }
        this.SET_IN_ACTION(true);

        const [err, loc] = await to<GeolocationPosition>(getLocation());

        if (ExpositionModule.ficheId !== null && ExpositionModule.ficheId.length === 22) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Vous êtes encore en zone, vous ne pouvez donc pas dépointer.',
                timeout: 5 * 1000,
            });
            return;
        }

        if (loc) {
            const uaResults = new UAParser().getResult();
            this.toggleClocking({loc, ua: uaResults});
        } else if (err) {
            let errorCode = 0;

            if ((err as Error).message !== 'geolocation_not_available') {
                errorCode = (err as GeolocationPositionError).code;
            }

            const errorTranslated = i18n.t(
                'pointage.geolocationPositionError'
            ) as unknown as string[];
            const errorMessage: string =
                errorCode > 0 && errorCode < 3 ? errorTranslated[errorCode] : errorTranslated[0];

            // appStateService.vibrate([1000, 1000, 500]);

            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: errorMessage,
                timeout: 10000,
            });
        }

        this.SET_IN_ACTION(false);
    }

    @Action
    public toggleClocking(payload: {loc: GeolocationPosition; ua: UAParser.IResult}) {
        ApplicationModule.setLoading(true);
        const {loc, ua} = payload;
        const ipAddr = 'Donnée non disponible.';
        const isManualChantier =
            UserModule.isClockingOnDefaultActivity !== false && UserModule.defaultActivityId;
        let matchedChantier: string | null = null;
        if (isManualChantier) {
            const {chantiers}: {chantiers: Array<Chantier>} = ChantierModule;
            const pIndex = chantiers.findIndex((ch) => ch.id === UserModule.defaultActivityId);
            if (pIndex !== -1) {
                matchedChantier = chantiers[pIndex].name;
            }
        }
        if (!this.clockingId) {
            let pointage = {
                id: shortGuid(),
                activityCode: matchedChantier,
                activityId: UserModule.defaultActivityId,
                activityMatched: matchedChantier !== null,
                detectionMode: isManualChantier
                    ? PointageDetectionMode.Manuel
                    : PointageDetectionMode.Automatique,
                askForGd: false,
                isGd: false,
                isWorkingRemotely: false,
                kmParcourus: 0,
                kmParcourusRetour: 0,
                transportMode: UserModule.lastChosenTransportMode,
                userId: UserModule.id,
                name: 'Pointage MyPyrop',
                status: PointageStatus.Started,
                startTime: new Date(),
                startInfo: {
                    id: shortGuid(),
                    browserName: ua.browser.name,
                    browserVersion: ua.browser.version,
                    cpuArchitecture: ua.cpu.architecture,
                    deviceModel: ua.device.model,
                    deviceType: ua.device.type,
                    deviceVendor: ua.device.vendor,
                    engineName: ua.engine.name,
                    engineVersion: ua.engine.version,
                    ipAddress: ipAddr,
                    latitude: loc.coords.latitude,
                    longitude: loc.coords.longitude,
                    position: {
                        coords: {
                            accuracy: loc.coords.accuracy,
                            latitude: loc.coords.latitude,
                            longitude: loc.coords.longitude,
                            altitude: loc.coords.altitude,
                            altitudeAccuracy: loc.coords.altitudeAccuracy,
                            heading: loc.coords.heading,
                            speed: loc.coords.speed,
                        },
                        timestamp: loc.timestamp,
                    },
                    name: 'Pointage',
                    osName: ua.os.name,
                    osVersion: ua.os.version,
                    eventType: PointageEventType.Start,
                    userAgent: ua.ua,
                } as ClockInEventData,
                version: 0,
            } as IClockInData;
            // Mutate events
            myPyropDb.setClockIn(pointage);
            this.SET_CLOCKIN(pointage);
            // Then, search for chantier
            pointage = detectChantier(pointage);
            // Mutate pointages
            myPyropDb.setClockIn(pointage);
            this.SET_CLOCKIN(pointage);
            this.SET_CLOCKINGID(pointage.id);
            SyncModule.addSyncAction({
                aType: ISyncActionType.PointageV2,
                payload: {pointage, type: 'START'},
            });
            ApplicationModule.callForceSync();
        } else if (this.clockingTime > 30) {
            const actualClockIn: IClockInData = this.currentClockInData as IClockInData;
            const pointage: IClockInData = {
                ...actualClockIn,
                stopTime: new Date(),
                stopInfo: {
                    id: shortGuid(),
                    browserName: ua.browser.name,
                    browserVersion: ua.browser.version,
                    cpuArchitecture: ua.cpu.architecture,
                    deviceModel: ua.device.model,
                    deviceType: ua.device.type,
                    deviceVendor: ua.device.vendor,
                    engineName: ua.engine.name,
                    engineVersion: ua.engine.version,
                    ipAddress: ipAddr,
                    latitude: loc.coords.latitude,
                    longitude: loc.coords.longitude,
                    position: {
                        coords: {
                            accuracy: loc.coords.accuracy,
                            latitude: loc.coords.latitude,
                            longitude: loc.coords.longitude,
                            altitude: loc.coords.altitude,
                            altitudeAccuracy: loc.coords.altitudeAccuracy,
                            heading: loc.coords.heading,
                            speed: loc.coords.speed,
                        },
                        timestamp: loc.timestamp,
                    },
                    name: 'Pointage',
                    osName: ua.os.name,
                    osVersion: ua.os.version,
                    eventType: PointageEventType.Stop,
                    userAgent: ua.ua,
                } as ClockInEventData,
                status: PointageStatus.Ended,
                version: actualClockIn.version + 1,
            };

            myPyropDb.setClockIn(pointage);
            this.SET_CLOCKIN(pointage);
            this.SET_CLOCKINGID(null);

            SyncModule.addSyncAction({
                aType: ISyncActionType.PointageV2,
                payload: {pointage, type: 'STOP'},
            });
            ApplicationModule.callForceSync();
        }
        ApplicationModule.setLoading(false);
    }

    @Action
    public updateCurrent(payload: {value: any; mutation: ClockingChangeType}) {
        if (!this.clockingId) {
            return;
        }
        const {value, mutation} = payload;
        let pointage = this.currentClockInData as IClockInData;
        switch (mutation) {
            case ClockingChangeType.Transport:
                pointage.transportMode = value;
                if (UserModule.lastChosenTransportMode !== value) {
                    UserModule.setLastChosenTransportMode(value);
                }
                pointage.version++;
                break;
            case ClockingChangeType.Detection:
                pointage.detectionMode = value;
                if (value === PointageDetectionMode.Automatique && pointage.activityCode) {
                    pointage.activityCode = '';
                }
                pointage = detectChantier(pointage);
                break;
            case ClockingChangeType.CodeChantier:
                pointage.activityCode = value;
                pointage = detectChantier(pointage);
                break;
            case ClockingChangeType.Description:
                pointage.description = value;
                pointage.version++;
                break;
            case ClockingChangeType.IsWorkingRemotely:
                pointage.isWorkingRemotely = value;
                pointage.version++;
                break;
            default:
                return;
        }
        myPyropDb.setClockIn(pointage);
        this.SET_CLOCKIN(pointage);
        SyncModule.addSyncAction({
            aType: ISyncActionType.ChangePointageV2,
            payload: {mutation, pointage},
        });
    }

    @Action
    public updateCurrentDayClockIn(payload: {value: any; mutation: ClockingChangeType}) {
        if (!this.todayClockIn) {
            return;
        }
        const {value, mutation} = payload;
        const copiedTodayClockIn = {...this.todayClockIn};
        switch (mutation) {
            case ClockingChangeType.HotelName:
                copiedTodayClockIn.hotelName = value;
                SyncModule.addSyncAction({
                    aType: ISyncActionType.ChangeHotelName,
                    payload: {
                        clockInDayId: this.todayClockIn?.id,
                        hotelName: value,
                        userId: UserModule.id,
                    },
                });
                break;
            case ClockingChangeType.Gd:
                if (
                    value &&
                    (copiedTodayClockIn.payedCompensationTypeValue &
                        ClockInDayPayedCompensationType.GRDEP) !==
                        ClockInDayPayedCompensationType.GRDEP
                ) {
                    copiedTodayClockIn.payedCompensationTypeValue |=
                        ClockInDayPayedCompensationType.GRDEP;
                } else if (
                    !value &&
                    (copiedTodayClockIn.payedCompensationTypeValue &
                        ClockInDayPayedCompensationType.GRDEP) ===
                        ClockInDayPayedCompensationType.GRDEP
                ) {
                    copiedTodayClockIn.payedCompensationTypeValue &=
                        ~ClockInDayPayedCompensationType.GRDEP;
                }
                SyncModule.addSyncAction({
                    aType: ISyncActionType.ChangeGrDep,
                    payload: {
                        clockInDayId: this.todayClockIn?.id,
                        grDep: value,
                        userId: UserModule.id,
                    },
                });
                break;
            default:
                return;
        }
        this.SET_CLOCKINDAY(copiedTodayClockIn);
    }

    @Action
    public async initializeStore() {
        const clocksin = await myPyropDb.getClocksInFor(UserModule.id);

        if (clocksin && Array.isArray(clocksin) && clocksin.length > 0) {
            let maxDate!: Date | undefined;
            let clockingId!: string | undefined;

            clocksin.forEach((event, index, array) => {
                if (!event.startTime) {
                    return;
                }
                // eslint-disable-next-line no-param-reassign
                array[index].startTime = reviveUTCDate(event.startTime);

                if (event.stopTime) {
                    // eslint-disable-next-line no-param-reassign
                    array[index].stopTime = reviveUTCDate(event.stopTime);
                }

                if (!isToday(event.startTime as Date)) {
                    return;
                }
                if (!maxDate || maxDate < event.startTime) {
                    maxDate = event.startTime as Date;
                    if (!event.stopTime) {
                        clockingId = event.id;
                    } else {
                        clockingId = undefined;
                    }
                }
            });
            this.SET_CLOCKSIN(clocksin);
            this.SET_CLOCKINGID(clockingId ?? null);
        }

        this.SET_INITIALIZED(true);
    }

    @Action
    public async serverPointageDataUpdate(data: IClockInData) {
        const events: Array<IClockInData> = (
            cloneDeep(this.clocksin) as Array<IClockInData>
        ).filter((ev) => ev.userId === data.userId);

        // update if needed
        const pIndex = events.findIndex((event) => event.id === data.id);
        if (pIndex !== -1) {
            if (data.version <= events[pIndex].version) {
                // ignore this version
                return;
            }
            // Got new version of this pointage
            events.splice(pIndex, 1);
        }
        let revivedStartDate: Date | undefined;
        let revivedStopDate: Date | undefined;
        if (data.startTime) {
            revivedStartDate = reviveUTCDate(data.startTime);
        }
        if (data.stopTime) {
            revivedStopDate = reviveUTCDate(data.stopTime);
        }
        // eslint-disable-next-line no-param-reassign
        data.startTime = revivedStartDate;
        data.stopTime = revivedStopDate;
        events.push(data);

        let clockingId;

        if (!data.stopTime) {
            clockingId = data.id;
        } else {
            clockingId = undefined;
        }

        await myPyropDb.clearClocksInFor(UserModule.id);
        await myPyropDb.setClocksIn(events);
        this.SET_CLOCKSIN(events);
        this.SET_CLOCKINGID(clockingId ?? null);
    }

    @Action
    public async serverClockInDayDataUpdate(data: IClockInDayData) {
        const safeData = {...data, day: reviveUTCDate(data.day)};
        if (Array.isArray(safeData.travels) && safeData.travels.length > 0) {
            safeData.travels.forEach((travel) => {
                travel.at = reviveUTCDate(travel.at);
            });
        }

        this.SET_CLOCKINDAY(safeData);
    }

    @Action
    public cleansBefore(beforeDate: Date) {
        const clocksin: Array<IClockInData> = cloneDeep(this.clocksin);

        const clocksInIds: string[] = [];

        clocksin.forEach((ev) => {
            if ((ev.startTime as Date).getTime() > beforeDate.getTime()) {
                return;
            }

            clocksInIds.push(ev.id);
        });

        const nClocksIn = clocksin.filter((ev: IClockInData) => clocksInIds.indexOf(ev.id) === -1);

        myPyropDb.setClocksIn(nClocksIn);

        this.SET_CLOCKSIN(nClocksIn);
    }

    public get currentClockInData() {
        if (!this.clockingId) {
            return undefined;
        }
        const pIndex = this.clocksin.findIndex((clk) => clk.id === this.clockingId);
        if (pIndex === -1) {
            return undefined;
        }

        return this.clocksin[pIndex];
    }

    public get clockingTime() {
        if (!this.clockingId) {
            return 0;
        }
        if (!this.currentClockInData) {
            return 0;
        }
        const now = new Date();
        return (now.getTime() - (this.currentClockInData.startTime as Date).getTime()) / 1000;
    }
}

export const PointageModule = getModule(MPointage);
