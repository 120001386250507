import {distance, reviveUTCDate} from '@/utils';
import {
    PointageEventType,
    IEvenementPointageData,
    ClockInEventData
} from '@/entities/evenement-pointage/evenement-pointage.types';
import {IChantier, IChantierData} from './chantier.types';

const MAX_KM = 1;

export class Chantier implements IChantier {
    readonly id: string;

    readonly name: string;

    readonly locationEnabled: boolean;

    readonly latitude?: number | undefined;

    readonly longitude?: number | undefined;

    readonly lastModifiedDate?: string | Date;

    constructor(data: IChantierData) {
        this.id = data.id;
        this.name = data.name;
        this.locationEnabled = data.locationEnabled;
        if (data.locationEnabled) {
            this.latitude = data.latitude;
            this.longitude = data.longitude;
        }
        if (data.lastModifiedDate) {
            this.lastModifiedDate = reviveUTCDate(data.lastModifiedDate);
        }
    }

    public isWithinLatLon(lat: number, lon: number) {
        if (!this.locationEnabled || !this.latitude || !this.longitude) {
            return false;
        }
        return distance(this.latitude, this.longitude, lat, lon, 'K') <= MAX_KM;
    }

    public isWithinPointage(event?: ClockInEventData) {
        if (!this.locationEnabled || !this.latitude || !this.longitude) {
            return false;
        }

        if (!event || event.eventType !== PointageEventType.Start) {
            return false;
        }

        return (
            distance(this.latitude, this.longitude, event.position.coords.latitude, event.position.coords.longitude, 'K') <= MAX_KM
        );
    }
}
