import i18n from '@/i18n';

/* eslint-disable-next-line no-shadow */
export enum FicheExpoActivite {
    Confinement = 1 << 0,
    Replis = 1 << 1,
    Aspiration = 1 << 2,
    Depoussierement = 1 << 3,
    NettoyageFin = 1 << 4,
    Retrait = 1 << 5,
    EvacuationDechets = 1 << 6,
    InstallationGenerale = 1 << 7,
    RepliGeneral = 1 << 8
}

/* eslint-disable-next-line no-shadow */
export enum FicheExpoAgentChimique {
    Chrysotile = 1 << 0,
    Amosite = 1 << 1,
    Melange = 1 << 2,
    Plomb = 1 << 3,

    Autre = 1 << 31
}

/* eslint-disable-next-line no-shadow */
export enum FicheExpoEPI {
    Combinaison = 1 << 0,
    Gants = 1 << 1,
    Bottes = 1 << 2,
    SousVetements = 1 << 3,
    DoubleCombi = 1 << 4,
    SurBottes = 1 << 5,
    Lunettes = 1 << 6,
    Casque = 1 << 7,
    Harnais = 1 << 8,
    AntiBruit = 1 << 9
}

/* eslint-disable-next-line no-shadow */
export enum MaskType {
    Aucun,
    DemiMasque,
    MasqueAdductionAir,
    MasqueVentilationAssistee
}

export interface IFicheExpositionData {
    id: string;
    activites: FicheExpoActivite;
    agentsChimiques: FicheExpoAgentChimique;
    agentsChimiquesAutres: string[];
    chantierId: string | null;
    dateEntreeEnZone?: string | Date;
    dateInitialisation: string | Date;
    dateSortieDeZone?: string | Date;
    dateFinRecuperation?: string | Date;
    epi: FicheExpoEPI;
    etape: number;
    masque: MaskType;
    possedeMasque: boolean;
    signature?: string;
    tauxEmpoussierement?: number;
    temperature?: number;
    tempsEnZoneEnSeconde: number;
    tempsMaxAccordeEnSeconde: number;
    version: number;
}

export interface IFicheExposition extends IFicheExpositionData {
    readonly dateEntreeEnZone?: Date;
    readonly dateInitialisation: Date;
    readonly dateSortieDeZone?: Date;
    readonly dateFinRecuperation?: Date;
}

export function agentsChimiquesProperties(): string[] {
    // eslint-disable-next-line no-restricted-globals
    return Object.keys(FicheExpoAgentChimique).filter(key => isNaN(Number(key)));
}

export function allAgentsChimiques(): any[] {
    return agentsChimiquesProperties().map((element, index) => {
        return {
            text: (i18n.t('agentsChimiques').valueOf() as string[])[index],
            value: element
        };
    });
}

export function epiProperties(): string[] {
    // eslint-disable-next-line no-restricted-globals
    return Object.keys(FicheExpoEPI).filter(key => isNaN(Number(key)));
}

export function allEpis(): any[] {
    return epiProperties().map((element, index) => {
        return {
            text: (i18n.t('epis').valueOf() as string[])[index],
            value: element
        };
    });
}

export function activiteProperties(): string[] {
    // eslint-disable-next-line no-restricted-globals
    return Object.keys(FicheExpoActivite).filter(key => isNaN(Number(key)));
}

export function allActivites(): any[] {
    return activiteProperties().map((element, index) => {
        return {
            text: (i18n.t('activites').valueOf() as string[])[index],
            value: element
        };
    });
}
