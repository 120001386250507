

















































































import firebase from 'firebase/app';
import 'firebase/auth';
import {Component, Vue} from 'vue-property-decorator';
import {ApplicationModule} from '@/store/modules/application';
import eventBus from '@/eventBus';
import ApplicationLoading from '@/components/ApplicationLoading.vue';
import AnonymousUserMenu from '@/components/Menus/AnonymousUserMenu.vue';
import LoggedUserMenu from '@/components/Menus/LoggedUserMenu.vue';
import HelpMenu from '@/components/Menus/HelpMenu.vue';
import NotificationHubClient from '@/components/NotificationHubClient.vue';
import NotificationsList from './components/User/NotificationsList.vue';
import myDb from '@/store/myPyropDb';
import {UserModule} from '@/store/modules/user';
import {SyncModule} from '@/store/modules/synchro';
import {PointageModule} from '@/store/modules/pointage';
import createSyncDataPlugin from '@/store/plugins/sync-plugin';
import {ChantierModule} from '@/store/modules/chantier';
import {ExpositionModule} from '@/store/modules/exposition';
import {resetPermission} from '@/permission';
import {AgenceModule} from './store/modules/agence';
import confetti from 'canvas-confetti';

const EVENTS = ['online', 'offline'];

const firebaseConfig = {
    apiKey: 'AIzaSyDBZhNW7pyhBLiXgaGnVbI3ec-7Np1LlJU',
    authDomain: 'europamiante-91d88.firebaseapp.com',
    databaseURL: 'https://europamiante-91d88.firebaseio.com',
    projectId: 'europamiante-91d88',
    storageBucket: 'europamiante-91d88.appspot.com',
    messagingSenderId: '655374842783',
    appId: '1:655374842783:web:e547dae5344e5e7ecf1baa',
};

@Component({
    components: {
        ApplicationLoading,
        LoggedUserMenu,
        AnonymousUserMenu,
        HelpMenu,
        NotificationHubClient,
        NotificationsList,
    },
})
export default class App extends Vue {
    private drawer = false;
    private dbInitialiazed = false;

    private get snackStatus() {
        return ApplicationModule.status;
    }

    private get online() {
        return ApplicationModule.online;
    }

    private get loading() {
        return ApplicationModule.loading;
    }

    private get authenticating() {
        return UserModule.authenticating;
    }

    private get authenticated() {
        return UserModule.authenticated;
    }

    private get userSyncValid() {
        return UserModule.syncValid;
    }

    private get displayAccessAlert() {
        const hasAccess = UserModule.syncedHasAccess;
        if (hasAccess === null) {
            return false;
        }
        return !hasAccess;
    }

    private get syncInProgress() {
        return SyncModule.inProgress;
    }

    private get hasSyncData() {
        return !SyncModule.syncFinished;
    }

    private get syncDataCount() {
        return SyncModule.waitCount;
    }

    private get isExtended() {
        return this.$route.meta?.hasNavigation !== undefined && this.$route.meta.hasNavigation;
    }

    private get isNavigationDisplayed() {
        return (
            this.$route.meta?.disableNavigation === undefined || !this.$route.meta.disableNavigation
        );
    }

    private get syncing() {
        return SyncModule.inProgress;
    }

    private closeSnack() {
        ApplicationModule.hideSnackbar();
    }

    public get appUpdated(): boolean {
        return ApplicationModule.updated;
    }

    public mounted(): void {
        EVENTS.forEach((event) => window.addEventListener(event, this.updateOnlineStatus));
        this.$nextTick(async () => {
            await myDb.initialize();

            // 1. Init user
            await UserModule.initializeStore();
            // Then, init others
            await SyncModule.initializeStore();
            await PointageModule.initializeStore();
            await ChantierModule.initializeStore();
            await ExpositionModule.initializeStore();
            await AgenceModule.initializeStore();
            if (UserModule.authenticated) {
                this.$store.commit('application/FORCE_REFRESH');
            }
            this.dbInitialiazed = true;

            this.initFirebaseIfNeeded();
            resetPermission();

            createSyncDataPlugin()(this.$store);
        });
        ApplicationModule.setLoading(false);

        eventBus.$on('confetti', this.sendConfetti);
    }

    public unmounted(): void {
        EVENTS.forEach((event) => window.removeEventListener(event, this.updateOnlineStatus));
        eventBus.$off('confetti');
    }

    private updateOnlineStatus() {
        const networkCondition = navigator.onLine || false;
        ApplicationModule.setOnline(networkCondition);
        if (networkCondition) {
            this.initFirebaseIfNeeded();
        }
    }

    private sendConfetti() {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: {y: 0.6},
            zIndex: 999
        });
    }

    private forceRefreshPage() {
        window.location.reload(true);
    }

    private forceSyncData() {
        this.$store.commit('application/FORCE_SYNC');
    }

    private initFirebaseIfNeeded() {
        if (ApplicationModule.online && !ApplicationModule.firebaseInitialized) {
            ApplicationModule.setFirebaseInitialized();
            firebase.initializeApp(firebaseConfig);
            UserModule.setAuthenticating(true);
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    UserModule.validateLoginAsync(user, true);
                } else {
                    UserModule.ResetToken();
                    UserModule.setAuthenticating(false);
                }
            });
        }
    }
}
