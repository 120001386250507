






































import {Vue, Component} from 'vue-property-decorator';

@Component({})
export default class Step1Component extends Vue {
    public maskChosen(value: number) {
        this.$emit('maskNoChosen', value);
    }
}
