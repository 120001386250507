


























































import {Vue, Component, PropSync} from 'vue-property-decorator';
import {ChangeEntrerEnZoneRequestType} from '@/models/viewmodels/fiche/EntrerEnZoneRequestViewModel';
import {MaskType} from '@/entities/fiche-exposition/fiche-exposition.types';
import {ExpositionModule} from '@/store/modules/exposition';
import {ApplicationModule} from '@/store/modules/application';
import {PointageModule} from '@/store/modules/pointage';
import Step0Component from './Step0.vue';
import Step1Component from './Step1.vue';
import Step2Component from './Step2.vue';
import Step3Component from './Step3.vue';
import Step4Component from './Step4.vue';
import Step5Component from './Step5.vue';
import Step6Component from './Step6.vue';

@Component({
    components: {
        Step0Component,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        Step5Component,
        Step6Component
    }
})
export default class EnteringZoneDialogComponent extends Vue {
    @PropSync('enteringZone')
    public syncedEnteringZone!: boolean;

    private get loading() {
        return ApplicationModule.loading;
    }

    private get ficheEnCours() {
        return ExpositionModule.ficheEnCours;
    }

    private get step() {
        return this.ficheEnCours?.etape || 0;
    }

    private get hasMask() {
        return this.ficheEnCours?.possedeMasque || false;
    }

    private set hasMask(value: boolean) {
        ExpositionModule.updateCurrent({
            value,
            mutation: !this.isNewFiche
                ? ChangeEntrerEnZoneRequestType.PossedeMasque
                : ChangeEntrerEnZoneRequestType.Creer | ChangeEntrerEnZoneRequestType.PossedeMasque
        });
    }

    private get maskNo() {
        return this.ficheEnCours?.masque || MaskType.Aucun;
    }

    private set maskNo(value: number) {
        ExpositionModule.updateCurrent({
            value,
            mutation: ChangeEntrerEnZoneRequestType.Masque
        });
    }

    private get epi() {
        return this.ficheEnCours?.epi || 0;
    }

    private set epi(value: number) {
        ExpositionModule.updateCurrent({
            value,
            mutation: ChangeEntrerEnZoneRequestType.EPI
        });
    }

    private get activite() {
        return this.ficheEnCours?.activites || 0;
    }

    private set activite(value: number) {
        ExpositionModule.updateCurrent({
            value,
            mutation: ChangeEntrerEnZoneRequestType.Activite
        });
    }

    private get agents() {
        return this.ficheEnCours?.agentsChimiques || 0;
    }

    private set agents(value: number) {
        ExpositionModule.updateCurrent({
            value,
            mutation: ChangeEntrerEnZoneRequestType.AgentsChimiques
        });
    }

    private get autresAgents() {
        return this.ficheEnCours?.agentsChimiquesAutres || [];
    }

    private set autresAgents(value: Array<string>) {
        ExpositionModule.updateCurrent({
            value,
            mutation: ChangeEntrerEnZoneRequestType.AutreAgentsChimiques
        });
    }

    private get currentFicheId() {
        return this.ficheEnCours?.id;
    }

    private get isNewFiche() {
        return (
            this.ficheEnCours?.id === undefined ||
            this.ficheEnCours?.id === null ||
            this.ficheEnCours.etape < 0 ||
            this.ficheEnCours.etape === 7
        );
    }

    private step0Completed(choice: boolean) {
        this.hasMask = choice;
    }

    private step1Completed(choice: number) {
        this.maskNo = choice;
    }

    private step2Completed(choice: any) {
        ExpositionModule.updateCurrent({
            value: +choice.temperature || undefined,
            mutation: ChangeEntrerEnZoneRequestType.Temperature
        });
        ExpositionModule.updateCurrent({
            value: +choice.empoussierement || undefined,
            mutation: ChangeEntrerEnZoneRequestType.TauxEmpoussierement
        });
        ExpositionModule.updateCurrent({
            value: null,
            mutation: ChangeEntrerEnZoneRequestType.EntrerAvecMasque
        });
    }

    private async handleExitZone() {
        ExpositionModule.updateCurrent({
            value: null,
            mutation: ChangeEntrerEnZoneRequestType.SortirDeZone
        });
    }

    private async handleGoToSignature() {
        ExpositionModule.updateCurrent({
            value: null,
            mutation: ChangeEntrerEnZoneRequestType.ValiderInformations
        });
    }

    private async handleEndAction(event: any) {
        ExpositionModule.updateCurrent({
            value: event.signature,
            mutation: ChangeEntrerEnZoneRequestType.SignerFiche
        });
        this.syncedEnteringZone = false;
        if (event.action === 2) {
            PointageModule.startStopPointage();
        }
    }
}
