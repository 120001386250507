import request from '@/utils/request';
import {IDataSyncResult} from '@/models/viewmodels/data/IDataSyncResult';
import {PictureUploadResult} from '@/models/viewmodels/PictureUploadResult';
import {ISyncActionResult} from '@/models/application/sync-action';

export const postSyncData = (data: any): Promise<ISyncActionResult> =>
    (request({
        url: '/Data',
        method: 'post',
        data
    }) as unknown) as Promise<ISyncActionResult>;

export const requestSyncData = (): Promise<IDataSyncResult> =>
    (request({
        url: '/Data',
        method: 'get'
    }) as unknown) as Promise<IDataSyncResult>;

export const postNewPicture = (formData: FormData, onUploadProgress: (progressEvent: any) => void): Promise<PictureUploadResult[]> =>
    (request({
        url: '/Picture/Upload',
        method: 'post',
        data: formData,
        maxBodyLength: 52428800,
        timeout: 300000, //5 * 60 * 1000
        onUploadProgress
    }) as unknown) as Promise<PictureUploadResult[]>;

export const forceRefreshRType = () => request({
    url: '/Salesforce/ForceRefreshRecordType',
    method: 'get'
});

export const forceRefreshAgence = () => request({
    url: '/Salesforce/ForceRefreshAgence',
    method: 'get'
});

export const forceRefreshChantier = () => request({
    url: '/Salesforce/ForceRefreshChantier',
    method: 'get'
});

export const forceRefreshFiche = () => request({
    url: '/Salesforce/ForceRefreshFiche',
    method: 'get'
});

export const forceRefreshAbsences = () => request({
    url: '/Salesforce/ForceRefreshAbsences',
    method: 'get'
});