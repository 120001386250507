import axios, {AxiosError} from 'axios';
import {UserModule} from '@/store/modules/user';
import {ApplicationModule} from '@/store/modules/application';
import router, {resetRouter} from '@/router';
import {resetPermission} from '@/permission';

const service = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_BASE_API}`, // url = base url + request url
    timeout: 25000
    // withCredentials: true // send cookies when cross-domain requests
});

// Request interceptors
service.interceptors.request.use(
    config => {
        const newConfig = {...config};
        // Add X-Access-Token header to every request, you can add other custom headers here
        if (UserModule.token) {
            newConfig.headers.Authorization = `Bearer ${UserModule.token}`;
        }
        return newConfig;
    },
    error => {
        Promise.reject(error);
    }
);

// Response interceptors
service.interceptors.response.use(
    response => {
        // Some example codes here:
        // code == 20000: success
        // code == 50001: invalid access token
        // code == 50002: already login in other place
        // code == 50003: access token expired
        // code == 50004: invalid user (user not exist)
        // code == 50005: username or password is incorrect
        // You can change this part for your own usage.
        const res = response.data;
        if (response.status !== 200) {
            ApplicationModule.showSnackbar({
                displayed: true,
                text: res.message || 'Erreur inconnue',
                color: 'error',
                timeout: 5 * 1000
            });
            return Promise.reject(new Error(res.message || 'Error'));
        }
        return response.data;
    },
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            UserModule.ResetToken();
            resetRouter();
            resetPermission();
            router.replace('/account/login');
        } else if (error.response?.status === 419) {
            ApplicationModule.showSnackbar({
                displayed: true,
                text: 'Veuillez ré-essayer de soumettre votre requête...',
                color: 'error',
                timeout: 15 * 1000
            });
            UserModule.forceRefreshToken().then(
                () => {        
                    window.location.reload();
                }
            );
        } else {
            let errMsg = error.message;
            if (errMsg === 'Network Error') {
                errMsg = 'Réseau actuellement indisponible...';
            }
            ApplicationModule.showSnackbar({
                displayed: true,
                text: errMsg,
                color: 'error',
                timeout: 5 * 1000
            });
        }
        return Promise.reject(error);
    }
);

export default service;
