











import {Vue, Component} from 'vue-property-decorator';
import AnonymousHome from '@/components/Home/AnonymousHome.vue';
import LockedHome from '@/components/Home/LockedHome.vue';
import LoggedHome from '@/components/Home/LoggedHome.vue';
import {UserModule} from '@/store/modules/user';

@Component({
    components: {
        AnonymousHome,
        LockedHome,
        LoggedHome
    }
})
export default class HomeComponent extends Vue {
    private get authenticated() {
        return UserModule.authenticated;
    }
}
