import {reviveUTCDate} from '@/utils';
import {
    IFicheExposition,
    FicheExpoActivite,
    FicheExpoAgentChimique,
    FicheExpoEPI,
    MaskType,
    IFicheExpositionData
} from './fiche-exposition.types';

export class FicheExposition implements IFicheExposition {
    public id: string;
    public activites: FicheExpoActivite;
    public agentsChimiques: FicheExpoAgentChimique;
    public agentsChimiquesAutres: string[];
    public chantierId: string | null;
    public dateEntreeEnZone?: Date;
    public dateInitialisation: Date;
    public dateSortieDeZone?: Date;
    public dateFinRecuperation?: Date;
    public epi: FicheExpoEPI;
    public etape: number;
    public masque: MaskType;
    public possedeMasque: boolean;
    public signature?: string;
    public tauxEmpoussierement?: number;
    public temperature?: number;
    public tempsEnZoneEnSeconde: number;
    public tempsMaxAccordeEnSeconde: number;
    public version: number;

    constructor(data: IFicheExpositionData) {
        this.id = data.id;
        this.activites = data.activites;
        this.agentsChimiques = data.agentsChimiques;
        this.agentsChimiquesAutres = data.agentsChimiquesAutres;
        this.chantierId = data.chantierId;
        if (data.dateEntreeEnZone) {
            this.dateEntreeEnZone = reviveUTCDate(data.dateEntreeEnZone);
        }
        this.dateInitialisation = reviveUTCDate(data.dateInitialisation);
        if (data.dateSortieDeZone) {
            this.dateSortieDeZone = reviveUTCDate(data.dateSortieDeZone);
        }
        if (data.dateFinRecuperation) {
            this.dateFinRecuperation = reviveUTCDate(data.dateFinRecuperation);
        }
        this.epi = data.epi;
        this.etape = data.etape;
        this.masque = data.masque;
        this.possedeMasque = data.possedeMasque;
        if (data.signature) {
            this.signature = data.signature;
        }
        if (data.tauxEmpoussierement) {
            this.tauxEmpoussierement = data.tauxEmpoussierement;
        }
        if (data.temperature) {
            this.temperature = data.temperature;
        }
        this.tempsEnZoneEnSeconde = data.tempsEnZoneEnSeconde;
        this.tempsMaxAccordeEnSeconde = data.tempsMaxAccordeEnSeconde;
        this.version = data.version;
    }

    public getTempsAvantZoneEnSeconde(now: Date) {
        if (this.etape !== 3 || !this.dateEntreeEnZone) {
            return 0;
        }

        return (this.dateEntreeEnZone.getTime() - now.getTime()) / 1000;
    }

    public getTempsRecuperationEnSeconde(now: Date) {
        if (this.etape < 4 || !this.dateFinRecuperation) {
            return 0;
        }

        return (this.dateFinRecuperation.getTime() - now.getTime()) / 1000;
    }

    public getTempsRestantEnZoneEnSeconde(now: Date) {
        if (this.etape !== 3 || !this.dateEntreeEnZone || !this.tempsMaxAccordeEnSeconde) {
            return 0;
        }

        return (
            (this.dateEntreeEnZone.getTime() - now.getTime()) / 1000 + this.tempsMaxAccordeEnSeconde
        );
    }

    public getTempsAvantSortieEnSeconde(now: Date) {
        const tRestantEnZone = this.getTempsRestantEnZoneEnSeconde(now);
        if (this.etape !== 3 || tRestantEnZone > 0) {
            return 0;
        }

        return tRestantEnZone + 7 * 60;
    }
}
