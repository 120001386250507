





























import {Vue, Component} from 'vue-property-decorator';
import VueSignature from '@/components/User/vue-signature.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import {ApplicationModule} from '@/store/modules/application';
import { padNumber } from '@/utils';

@Component({
    components: {SvgIcon, VueSignature}
})
export default class Step6Component extends Vue {
    private goToNextStep(action: number) {
        const signPad = (this.$refs.signPad as unknown) as VueSignature;
        if (signPad.isEmpty) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une fiche d'exposition ne peut-être validée sans être signée.",
                timeout: 10000
            });
            return;
        }

        const nowDateTime = new Date();
        
        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear()
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes())
        ].join(':')}`;

        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200 // stroke positionY, > default 40
        });

        this.$emit('gotonext', {
            signature: signPad.save('image/jpeg'),
            action
        });
    }
}
