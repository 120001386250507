

















import {PropType} from 'vue';
import {Vue, Component, Prop} from 'vue-property-decorator';
import filters from '@/modules/filters';
import {
    MIN_KILOMETERS_GRDEP,
    TravelDirection,
    TravelStatus,
    ITravelData,
    ClockInDayPayedCompensationType,
} from '@/entities/pointage/pointage.types';

@Component({
    components: {},
    filters,
})
export default class UserDayTravelComponent extends Vue {
    @Prop({type: Object as PropType<ITravelData>, required: true}) public travel!: ITravelData;

    public get computedTravelColor() {
        return this.travel.status === TravelStatus.Calculated ? 'success' : 'error';
    }
    
    public get computedTravelIcon() {
        return this.travel.direction === TravelDirection.Way1 ? 'mdi-crane' : 'mdi-home';
    }
}
