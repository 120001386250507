/* eslint-disable-next-line no-shadow */
export enum ClockingChangeType {
    // tslint:disable:no-bitwise
    None = 0,
    Transport = 1 << 0,
    Detection = 1 << 1,
    CodeChantier = 1 << 2,
    Description = 1 << 3,
    HotelName = 1 << 4,
    Gd = 1 << 5,
    AddPicture = 1 << 6,
    DeletePicture = 1 << 7,
    IsWorkingRemotely = 1 << 8,
}

export class ClockingExtraProperty {
    public changes: ClockingChangeType = ClockingChangeType.None;
    public transportMode = '';
    public detection = '';
    public codeChantier = '';
    public description = '';
    public isGd = false;
    public hotelName = '';
    public receipt = '';
}
