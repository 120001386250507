








































































































import {Vue, Component} from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import filters from '@/modules/filters';
import {PointageModule} from '@/store/modules/pointage';
import {
    MIN_KILOMETERS_GRDEP,
    ITravelData,
    ClockInDayPayedCompensationType,
} from '@/entities/pointage/pointage.types';

import UserDayTravelComponent from '@/components/User/DayTravel.vue';
import {ClockingChangeType} from '@/models/viewmodels/user/ClockingExtraProperty';
import { PictureUploadResult } from '@/models/viewmodels/PictureUploadResult';
import { ApplicationModule } from '@/store/modules/application';
import { HTMLInputEvent } from '@/models/viewmodels/html/HTMLInputEvent';
import { to } from '@/utils';
import {postNewPicture} from '@/api/data';

@Component({
    components: {UserDayTravelComponent},
    filters,
})
export default class UserClockinDayComponent extends Vue {
    private cancelDebounce = false;
    public otherError = '';
    public pictures: PictureUploadResult[] = [];

    public uploadProgress = 0;
    public uploadingFile = false;

    private debouncer!: (value: string, mutation: any) => void;

    public mounted() {
        this.$nextTick(() => {
            this.debouncer = debounce((value: string, mutation: ClockingChangeType) => {
                if (this.cancelDebounce) {
                    this.cancelDebounce = false;
                    return;
                }
                PointageModule.updateCurrentDayClockIn({
                    value,
                    mutation,
                });
            }, 1500);
        });
    }
    public get travels(): Array<ITravelData> {
        return PointageModule.todayClockIn?.travels ?? [];
    }

    public get computedTemporaryKilometersFromHome(): number {
        return PointageModule.todayClockIn?.temporaryKilometersToHome ?? 0;
    }

    public get computedMinKilometersGrDep(): number {
        return MIN_KILOMETERS_GRDEP;
    }

    public get computedCanActivateGrDep(): boolean {
        return (
            PointageModule.todayClockIn?.askForGrDep ??
            this.computedTemporaryKilometersFromHome >= MIN_KILOMETERS_GRDEP
        );
    }

    public get isGd() {
        const safePayedCompensationTypeValue =
            PointageModule.todayClockIn?.payedCompensationTypeValue ??
            ClockInDayPayedCompensationType.NULL;
        return (
            (safePayedCompensationTypeValue & ClockInDayPayedCompensationType.GRDEP) ===
            ClockInDayPayedCompensationType.GRDEP
        );
    }

    public set isGd(value: boolean) {
        PointageModule.updateCurrentDayClockIn({
            value,
            mutation: ClockingChangeType.Gd,
        });
    }

    public get hotelName() {
        return PointageModule.todayClockIn?.hotelName || '';
    }

    public set hotelName(value: string) {
        this.debouncer(value, ClockingChangeType.HotelName);
    }

    public forceValidateHotelName(value: string) {
        if (value !== this.hotelName) {
            this.cancelDebounce = true;
            PointageModule.updateCurrentDayClockIn({
                value,
                mutation: ClockingChangeType.HotelName,
            });
        }
    }
    
    public getImage(index: number) {
        return filters.imagizer(`${this.pictures[index].id}${this.pictures[index].ext}`, false, 64);
    }

    public takePicture() {
        (this.$refs.fileInput as HTMLInputElement).click();
    }

    public async onFileChanged(event?: HTMLInputEvent) {
        if (!event || !event.target || !event.target.files) {
            return;
        }
        const file: File = event.target.files[0];
        if (!file) {
            return;
        }
        if (this.uploadingFile) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Un envoi est déjà en cours...',
                timeout: 5 * 1000,
            });
            return;
        }

        this.otherError = '';
        this.uploadProgress = 0;
        this.uploadingFile = true;

        const formData = new FormData();
        formData.append('image', file, file.name);
        const [, response] = await to(
            postNewPicture(formData, (event) => {
                this.uploadProgress = Math.round((100 * event.loaded) / event.total);
            })
        );
        this.uploadingFile = false;
        if (response && Array.isArray(response) && response.length > 0 && response[0].id) {
            this.pictures.push(response[0]);
            PointageModule.updateCurrentDayClockIn({
                value: response[0].id,
                mutation: ClockingChangeType.AddPicture,
            });
        } else {
            this.otherError = "Le reçu n'a pas pu être envoyé. Merci de ré-essayer dans une zone avec une bonne connexion à internet."
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une erreur est survenue lors de l'envoie de votre justificatif.",
                timeout: 5 * 1000,
            });
        }
    }

    public remove(index: number): void {
        PointageModule.updateCurrentDayClockIn({
            value: this.pictures[index].id,
            mutation: ClockingChangeType.DeletePicture,
        });
        this.pictures.splice(index, 1);
    }
}
