import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {Chantier} from '@/entities/chantier/chantier';
import {IChantierData} from '@/entities/chantier/chantier.types';
import {to} from '@/utils';

export interface IChantierState {
    chantiers: Array<Chantier>;
}

@Module({dynamic: true, store, name: 'chantier', namespaced: true})
class MChantier extends VuexModule implements IChantierState {
    public chantiers: Array<Chantier> = [];

    @Mutation
    private SET_CHANTIERS(chantiers: Array<Chantier>) {
        this.chantiers = chantiers;
    }

    @Action
    public async initializeStore() {
        const [, chantiers] = await to(myPyropDb.getChantiers());

        if (chantiers && Array.isArray(chantiers) && chantiers.length > 0) {
            this.SET_CHANTIERS(chantiers.map(elem => new Chantier(elem)));
        }
    }

    @Action
    public async serverChantierDataUpdate(data: Array<IChantierData>) {
        myPyropDb.setChantiers(data);
        this.SET_CHANTIERS(data.map(elem => new Chantier(elem)));
    }
}

export const ChantierModule = getModule(MChantier);
