import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// Translation provided by Vuetify (typescript)
import fr from 'vuetify/src/locale/fr';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#C20E1D',
                secondary: '#9F000C',
                accent: '#770009',
                error: '#E32E3B',
                warning: '#EE9B23',
                info: '#448AFF',
                success: '#43A047'
            }
        }
    },
    lang: {
        locales: {fr},
        current: 'fr'
    }
});
