import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import UAParser from 'ua-parser-js';
import {SnackbarOptions} from '@/models/application/snackbar-options';
import store from '@/store';
import {ISyncActionType} from '@/models/application/sync-action';
import {SyncModule} from './synchro';

export interface IApplicationState {
    loading: boolean;
    online: boolean;
    firebaseInitialized: boolean;
    error: any;
    status: SnackbarOptions;
    jSonSubscription: string;
    updated: boolean;
}

@Module({dynamic: true, store, name: 'application', namespaced: true})
class MApplication extends VuexModule implements IApplicationState {
    public loading = true;
    public online = navigator.onLine || false;
    public firebaseInitialized = false;
    public error = null;
    public status = {
        color: '',
        displayed: false,
        text: '',
        timeout: -1
    };
    public jSonSubscription = '';
    public updated = false;

    // Extra dumb forceRefresh
    public forceRefresh = 0;
    // Extra dumb forceRefresh
    public forceSync = 0;

    @Mutation
    private SET_LOADING(loading: boolean) {
        this.loading = loading;
    }

    @Mutation
    private SET_ONLINE(online: boolean) {
        this.online = online;
    }

    @Mutation
    private SET_FIREBASEINITIALIZED(firebaseInitialized: boolean) {
        this.firebaseInitialized = firebaseInitialized;
    }

    @Mutation
    private SET_ERROR(error: any) {
        this.error = error;
    }

    @Mutation
    private SET_STATUS(status: SnackbarOptions) {
        this.status = status;
    }

    @Mutation
    private SET_SUBSCRIPTION(jSonSubscription: string) {
        this.jSonSubscription = jSonSubscription;
    }

    @Mutation
    private FORCE_REFRESH() {
        this.forceRefresh++;
    }

    @Mutation
    private FORCE_SYNC() {
        this.forceSync++;
    }

    @Mutation
    private SET_UPDATED() {
        this.updated = true;
    }

    @Action
    public setLoading(loading: boolean) {
        this.SET_LOADING(loading);
    }

    @Action
    public setOnline(online: boolean) {
        this.SET_ONLINE(online);
    }

    @Action
    public setFirebaseInitialized() {
        this.SET_FIREBASEINITIALIZED(true);
    }

    @Action
    public clearError() {
        this.SET_ERROR(null);
    }

    @Action
    public setError(error: any) {
        this.SET_ERROR(error);
        this.SET_STATUS({
            color: 'error',
            displayed: true,
            text: error.message,
            timeout: 5 * 1000
        });
    }

    @Action
    public showSnackbar(status: SnackbarOptions) {
        this.SET_STATUS(status);
    }

    @Action
    public hideSnackbar() {
        this.SET_STATUS({} as SnackbarOptions);
    }

    @Action
    public setUpdated() {
        this.SET_UPDATED();
    }

    @Action
    public updateSubscription(jsonSub: PushSubscriptionJSON) {
        // * 1. Compare subscriptions & update if needed.
        if (this.jSonSubscription) {
            const parsedJSonSub: PushSubscriptionJSON = JSON.parse(this.jSonSubscription);
            if (
                parsedJSonSub.endpoint === jsonSub.endpoint &&
                parsedJSonSub.keys?.auth === jsonSub.keys?.auth &&
                parsedJSonSub.keys?.p256dh === jsonSub.keys?.p256dh
            ) {
                // ! Subscription didn't changed
                return;
            }
        }

        const uaResults = new UAParser().getResult();

        SyncModule.addSyncAction({
            aType: ISyncActionType.PushRegistration,
            payload: {
                sub: jsonSub,
                ua: {
                    browserName: uaResults.browser.name,
                    browserVersion: uaResults.browser.version,
                    cpuArchitecture: uaResults.cpu.architecture,
                    deviceModel: uaResults.device.model,
                    deviceType: uaResults.device.type,
                    deviceVendor: uaResults.device.vendor,
                    engineName: uaResults.engine.name,
                    engineVersion: uaResults.engine.version,
                    osName: uaResults.os.name,
                    osVersion: uaResults.os.version,
                    userAgent: uaResults.ua
                }
            }
        });
        this.SET_SUBSCRIPTION(JSON.stringify(jsonSub));
    }

    @Action
    public callForceSync() {
        this.FORCE_SYNC();
    }
}

export const ApplicationModule = getModule(MApplication);
