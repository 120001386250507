import short from 'short-uuid';
import {IUserClaim} from './entities/user/user.types';
import {getJoursFeries} from './utils/fetes';

export async function to<T>(promise: Promise<T>): Promise<[any, T | undefined]> {
    try {
        const data = await promise;
        return [null, data];
    } catch (err) {
        return [err, undefined];
    }
}

export const areSameDate = (firstDate: Date, secondDate: Date): boolean => {
    return (
        firstDate.getDate() === secondDate.getDate() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getFullYear() === secondDate.getFullYear()
    );
};

export const isToday = (someDate: Date): boolean => {
    const today = new Date();
    return areSameDate(today, someDate);
};

export const getFirstDayOfTheWeek = (d: Date): Date => {
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
};

export const addDays = function addDays(date: Date, days: number): Date {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

export const reviveUTCDate = (myDate: Date | string): Date => {
    const needsRevive = typeof myDate === 'string';
    if (needsRevive) {
        const strDate = myDate as string;
        const plusIndex = strDate.lastIndexOf('+');
        const isUTC = strDate.endsWith('Z') || plusIndex === 19;
        return new Date(`${strDate}${isUTC ? '' : 'Z'}`);
    }
    return myDate as Date;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function replacer(tpl: string, data: any): string {
    if (!tpl) {
        return '';
    }
    return tpl.replace(/\$\(([^)]+)?\)/g, ($1, $2) => data[$2]);
}

export function parseDate(date: string): string {
    if (!date) {
        return '';
    }

    const [day, month, year] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function formatDate(date: string): string {
    if (!date) {
        return '';
    }

    const [year, month, day] = date.split('-');
    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
}

export function getAbsenceApprovalColor(etat: string): string {
    switch (etat) {
        case 'Demande initiale':
            return 'lime lighten-2';
        case 'Vérification RH':
            return 'light-green lighten-2';
        case 'Validée':
            return 'green lighten-2';
        case 'Refusée':
            return 'red lighten-2';
        case 'Annulée':
            return 'amber lighten-2';
        default:
            return 'primary';
    }
}

export function getAbsenceApprovalIcon(etat: string): string {
    switch (etat) {
        case 'Demande initiale':
            return 'mdi-calendar-plus';
        case 'Vérification RH':
            return 'mdi-calendar-clock';
        case 'Validée':
            return 'mdi-calendar-check';
        case 'Refusée':
            return 'mdi-calendar-alert';
        case 'Annulée':
            return 'mdi-calendar-remove';
        default:
            return 'mdi-calendar-question';
    }
}

export function getAcompteApprovalColor(etat: string): string {
    switch (etat) {
        case 'Demande initiale':
            return 'lime lighten-2';
        case 'En cours de vérification':
            return 'light-green lighten-2';
        case 'En cours de traitement':
            return 'light-green lighten-1';
        case 'Versé':
            return 'green lighten-2';
        case 'Déduit salaire':
            return 'green lighten-1';
        case 'Refusé':
            return 'red lighten-2';
        case 'Annulé':
            return 'amber lighten-2';
        default:
            return 'primary';
    }
}

/**
 * Generates a GUID string.
 * @returns {String} The generated GUID.
 * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
 * @author Slavik Meltser (slavik@meltser.info).
 * @link https://slavik.meltser.info/?p=142
 */
export function guid(): string {
    function p8(s?: boolean) {
        const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
        return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
    }
    return p8() + p8(true) + p8(true) + p8();
}

export function shortGuid(): string {
    return short.generate();
}

export function padNumber(value: number): string {
    const pad = '00';
    return pad.substring(0, pad.length - String(value).length) + value;
}

/**
 * Calculates the distance between two points (given the latitude/longitude of those points).
 * @returns {Number} The distance.
 * @param lat1 Latitude of point1 (in decimal degrees)
 * @param lon1 Longitude of point1 (in decimal degrees)
 * @param lat2 Latitude of point2 (in decimal degrees)
 * @param lon1 Longitude of point2 (in decimal degrees)
 * @param unit the unit you desire for results ('M' is statute miles (default), 'K' is kilometers and 'N' is nautical miles)
 */
export function distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string): number {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    }
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
        dist *= 1.609344;
    }
    if (unit === 'N') {
        dist *= 0.8684;
    }
    return dist;
}

export async function getLocation(): Promise<GeolocationPosition> {
    return new Promise(
        (resolve: (position: GeolocationPosition) => void, reject: (error: GeolocationPositionError | Error) => void) => {
            if (!('geolocation' in navigator)) {
                reject(new Error('geolocation_not_available'));
            }

            navigator.geolocation.getCurrentPosition(
                pos => {
                    resolve(pos);
                },
                err => {
                    reject(err);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 30000,
                    maximumAge: 120000
                }
            );
        }
    );
}

export function urlB64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const hasClaim = (claims: Array<IUserClaim>, type: string, value: string): boolean => {
    return claims.filter(c => c.type === type && c.value === value).length === 1;
};

export const isWorkingDay = (
    dtDate: Date,
    cache: {[year: number]: {[fete: string]: Date}}
): boolean => {
    if (dtDate.getDay() === 0 || dtDate.getDay() === 6) {
        // exclude Saturday & Sunday
        return false;
    }

    const year = dtDate.getFullYear();

    let fetesThisYear = cache[year];
    if (!fetesThisYear) {
        // Caching dates for future use.
        fetesThisYear = getJoursFeries(year, {alsace: false});
        // eslint-disable-next-line no-param-reassign
        cache[year] = fetesThisYear;
    }

    return (
        Object.values(fetesThisYear).filter(dateJourFerie => areSameDate(dateJourFerie, dtDate))
            .length === 0
    );
};

export const getWorkingDaysAmount = (
    startDate: Date,
    endDate: Date,
    cache: {[year: number]: {[fete: string]: Date}}
): number => {
    if (!startDate || !endDate) {
        return 0;
    }

    if (startDate.getTime() > endDate.getTime()) {
        return 0;
    }

    const total = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    let i = 0;
    let nbDays = 0;
    let itDate = startDate;
    while (i < total) {
        if (isWorkingDay(itDate, cache)) {
            nbDays++;
        }
        itDate = addDays(itDate, 1);
        i++;
    }

    return nbDays;
};
