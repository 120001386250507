import request from '@/utils/request';
import {IFicheExpositionData} from '@/entities/fiche-exposition/fiche-exposition.types';

export const getFichesExpositions = (dateStr: string): Promise<Array<IFicheExpositionData>> =>
    (request({
        url: `/User/GetDayExpositions/${dateStr}`,
        method: 'get'
    }) as unknown) as Promise<Array<any>>;

export const postExpoAccidentelle = (description: string): Promise<string> =>
    (request({
        url: '/user/AddExpoAccidentelle',
        method: 'POST',
        data: {description}
    }) as unknown) as Promise<string>;
