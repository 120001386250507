




import {Component, Vue, Watch} from 'vue-property-decorator';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import eventBus from '@/eventBus';

@Component({
    name: 'NotificationHubClient'
})
export default class extends Vue {
    private connection: HubConnection | null = null;
    private retryFirstConnectionTimer: number | null = null;
    private messageQueue: string[] = [];

    public mounted() {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_SIGNALR}`)
            .withAutomaticReconnect([15000, 30000, 90000])
            .build();

            this.connection.onreconnected(this.proccessQueue);

        eventBus.$on('notify-server', this.sendNotification);
    }

    @Watch('connection')
    private async onConnectionChanged(value: HubConnection | null) {
        if (value) {
            try {
                await value.start();

                value.on("ReceiveMessage", (message) => {
                    eventBus.$emit('notification', message);
                });

                value.on("Confetti", () => {
                    eventBus.$emit('confetti');
                });

                await this.proccessQueue();
            } catch(error) {
                if (error?.name === 'TypeError' && error?.message === 'Failed to fetch') {
                    this.retryFirstConnectionTimer = window.setTimeout(this.retryFirstConnection, 30000);
                }
                console.error(error);
            }
        }
    }

    private async retryFirstConnection() {
        if (this.retryFirstConnectionTimer) {
            window.clearTimeout(this.retryFirstConnectionTimer);
            this.retryFirstConnectionTimer = null;
        }
        await this.onConnectionChanged(this.connection);
    }

    public async beforeUnmount() {
        if (this.retryFirstConnectionTimer) {
            window.clearTimeout(this.retryFirstConnectionTimer);
            this.retryFirstConnectionTimer = null;
        }
        if (this.connection) {
            await this.connection.stop();
            this.connection = null;
        }
        eventBus.$off('notify-server');
    }

    private async sendNotification(message: string) {
        if (this.connection && this.connection.state === HubConnectionState.Connected) {
            try {
                await this.connection.invoke("ReceiveFromClient", message);
            } catch(error) {
                console.error(error);
            }
        } else {
            this.messageQueue.push(message);
        }
    }

    private async proccessQueue() {
        if (!this.connection) {
            return;
        }

        let message;
        do {
            message = this.messageQueue.shift();
            if (!message) { break; }
            await this.connection.invoke("ReceiveFromClient", message);
        } while(message !== undefined);
    }
}
