function getBaseConfig() {
    let baseURI = 'http://localhost:5010';
    let APIEndpoint: string = baseURI;
    let IMGEndpoint: string = baseURI;
    switch (process.env.VUE_APP_ENV) {
        case 'production':
            baseURI = 'https://mypyrop.europamiante.fr';

            APIEndpoint = `${baseURI}/api`;
            IMGEndpoint = `${baseURI}/images`;
            break;
        case 'staging':
            baseURI = 'https://devmypyrop.europamiante.fr';

            APIEndpoint = `${baseURI}/api`;
            IMGEndpoint = `${baseURI}/images`;
            break;
        case 'dev':
            baseURI = 'https://eamypyrop.devlogica.com';

            APIEndpoint = `${baseURI}/api`;
            IMGEndpoint = `${baseURI}/images`;
            // eslint-disable-next-line no-console
            console.log(`Env=${process.env.NODE_ENV},Api Endpoint=${APIEndpoint}`);
            break;
        default:
            break;
    }
    // eslint-disable-next-line no-console
    console.log(`Env=${process.env.VUE_APP_ENV},Api Endpoint=${APIEndpoint}`);

    return {
        baseURI,
        APIEndpoint,
        IMGEndpoint
    };
}

const config = getBaseConfig();

export const {baseURI} = config;
export const {APIEndpoint} = config;
export const {IMGEndpoint} = config;

// export const IMGEndpoint: string = 'https://img1.eat4week.com';
// export const WEBPEndpoint: string = 'https://img2.eat4week.com';
// export const MaxRecipeCategoryName: number = 50;
// export const MaxRecipeStepDescription: number = 250;
export const HeaderXSRFTokenName = 'x-xsrf-token';
// export const IngredientsMaxCategory: number = 18;
export const BlankImg =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export function revisedRandId(): string {
    return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(2, 10);
}
