import firebase from 'firebase/app';
import 'firebase/auth';
import {VuexModule, Module, Action, Mutation, getModule} from 'vuex-module-decorators';
import {resetRouter} from '@/router';
import {resetPermission} from '@/permission';
import {IUserData, IUserClaim, NotificationMessage} from '@/entities/user/user.types';
import { PointageTransportMode } from '@/entities/pointage/pointage.types';
import store from '@/store';
import myPyropDb from '@/store/myPyropDb';
import {to, reviveUTCDate} from '@/utils';
import i18n from '@/i18n';
import {ApplicationModule} from './application';
import eventBus from '@/eventBus';
import _, { cloneDeep } from 'lodash';

export interface IUserState {
    id: string | null;
    anonymousLogin: boolean;
    authenticating: boolean;
    companyPosition: string | null;
    email: string | null;
    firstName: string | null;
    hasAccess: boolean;
    hasSS3orSS4: boolean;
    homeAddressLine1: string | null;
    homeAddressLine2: string | null;
    homeCity: string | null;
    homeZipCode: string | null;
    pictureUrl: string | null;
    lastName: string | null;
    lastSyncDate: Date | null;
    lastChosenTransportMode: PointageTransportMode;
    medicalVisitOk: boolean;
    token: string | null;
    claims: Array<IUserClaim>;
    isClockingOnDefaultActivity: boolean;
    defaultActivityId: string | null;
    notifications: Array<NotificationMessage>;
}

@Module({dynamic: true, store, name: 'user', namespaced: true})
class MUser extends VuexModule implements IUserState {
    public id: string | null = null;
    public anonymousLogin = false;
    public authenticating = false;
    public email: string | null = null;
    public companyPosition: string | null = null;
    public firstName: string | null = null;
    public hasAccess = false;
    public hasSS3orSS4 = false;
    public homeAddressLine1: string | null = null;
    public homeAddressLine2: string | null = null;
    public homeCity: string | null = null;
    public homeZipCode: string | null = null;
    public pictureUrl: string | null = null;
    public lastName: string | null = null;
    public lastSyncDate: Date | null = null;
    public lastChosenTransportMode: PointageTransportMode = PointageTransportMode.NULL;
    public medicalVisitOk = false;
    public token: string | null = null;
    public claims: Array<IUserClaim> = [];
    public isClockingOnDefaultActivity = false;
    public defaultActivityId: string | null = null;
    public notifications: Array<NotificationMessage> = [];

    @Mutation
    private SET_ID(id: string | null) {
        this.id = id;
    }

    @Mutation
    private SET_ANONYMOUS(anonymous: boolean) {
        this.anonymousLogin = anonymous;
    }

    @Mutation
    private SET_AUTHENTICATING(authenticating: boolean) {
        this.authenticating = authenticating;
    }

    @Mutation
    private SET_COMPANY_POSITION(companyPosition: string | null) {
        this.companyPosition = companyPosition;
    }

    @Mutation
    private SET_EMAIL(email: string | null) {
        this.email = email;
    }

    @Mutation
    private SET_FIRSTNAME(firstName: string | null) {
        this.firstName = firstName;
    }

    @Mutation
    private SET_HASACCESS(hasAccess: boolean) {
        this.hasAccess = hasAccess;
    }

    @Mutation
    private SET_HASSS3ORSS4(hasSS3orSS4: boolean) {
        this.hasSS3orSS4 = hasSS3orSS4;
    }

    @Mutation
    private SET_HOME_ADDRESS1(homeAddressLine1: string | null) {
        this.homeAddressLine1 = homeAddressLine1;
    }

    @Mutation
    private SET_HOME_ADDRESS2(homeAddressLine2: string | null) {
        this.homeAddressLine2 = homeAddressLine2;
    }

    @Mutation
    private SET_HOME_CITY(homeCity: string | null) {
        this.homeCity = homeCity;
    }

    @Mutation
    private SET_HOME_ZIPCODE(homeZipCode: string | null) {
        this.homeZipCode = homeZipCode;
    }

    @Mutation
    private SET_PICTURE_URL(pictureUrl: string | null) {
        this.pictureUrl = pictureUrl;
    }

    @Mutation
    private SET_LASTNAME(lastName: string | null) {
        this.lastName = lastName;
    }

    @Mutation
    private SET_LASTSYNCDATE(lastSyncDate: Date | null) {
        this.lastSyncDate = lastSyncDate;
    }

    @Mutation
    private SET_LASTCHOSENTRANSPORTMODE(lastChosenTransportMode: PointageTransportMode) {
        this.lastChosenTransportMode = lastChosenTransportMode;
    }

    @Mutation
    private SET_MEDICALVISITOK(medicalVisitOk: boolean) {
        this.medicalVisitOk = medicalVisitOk;
    }

    @Mutation
    private SET_TOKEN(token: string | null) {
        this.token = token;
    }

    @Mutation
    private SET_CLAIMS(claims: Array<IUserClaim>) {
        this.claims = claims;
    }

    @Mutation
    private SET_DEFAULTACTIVITY(activity: string | null) {
        this.defaultActivityId = activity;
    }

    @Mutation
    private SET_ISCLOCKINGONDEFAULTACTIVITY(value: boolean) {
        this.isClockingOnDefaultActivity = value;
    }

    @Mutation
    private SET_NOTIFICATIONS(value: Array<NotificationMessage>) {
        this.notifications = value;
    }

    @Action
    public async validateLoginAsync(user: firebase.User, forceRefresh: boolean) {
        const token = await user.getIdToken(forceRefresh);
        this.SET_ANONYMOUS(user.isAnonymous);
        this.SET_TOKEN(token);

        if (this.id) {
            myPyropDb.setUser({
                id: this.id,
                authenticated: true,
                defaultActivityId: this.defaultActivityId || undefined,
                hasAccess: this.hasAccess,
                hasSS3orSS4: this.hasSS3orSS4,
                isClockingOnDefaultActivity: this.isClockingOnDefaultActivity,
                medicalVisitOk: this.medicalVisitOk,
                email: this.email || undefined,
                firstName: this.firstName || undefined,
                lastName: this.lastName || undefined,
                lastSyncDate: this.lastSyncDate || undefined,
                lastChosenTransportMode: this.lastChosenTransportMode,
                token,
                anonymous: this.anonymousLogin,
                jsonSubscription: ApplicationModule.jSonSubscription,
                claims: this.claims
            });
        }

        resetRouter();
        ApplicationModule.showSnackbar({
            displayed: true,
            color: 'success',
            text: i18n.t('login.success').toString(),
            timeout: 5 * 1000
        });

        this.SET_AUTHENTICATING(false);
    }

    @Action
    public async login(userInfo: {username: string; password: string}) {
        if (!ApplicationModule.online || this.authenticated) {
            return;
        }
        ApplicationModule.setLoading(true);
        ApplicationModule.clearError();
        // this.SET_AUTHENTICATING(true);
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        let {username} = userInfo;
        const {password} = userInfo;
        username = username.trim();
        const [err] = await to(firebase.auth().signInWithEmailAndPassword(username, password));
        if (err) {
            const code: string = err.code.split('/')[1];
            const error = code.replace(new RegExp('-', 'g'), '_');
            ApplicationModule.setError({
                code: err.code,
                message: i18n.t(`firebase.error.${error}`).toString()
            });
            // this.SET_AUTHENTICATING(false);
        }
        ApplicationModule.setLoading(false);
    }

    @Action
    public async qrCodeLogin(qrcode: string) {
        if (!ApplicationModule.online || this.authenticated) {
            return;
        }
        ApplicationModule.setLoading(true);
        ApplicationModule.clearError();
        // this.SET_AUTHENTICATING(true);
        await firebase.auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);    
            
        const [err, userCredential] = await to(firebase.auth().signInAnonymously());
        if (userCredential && userCredential.user) {
            this.SET_ID(qrcode);
        } else if (err) {
            const code: string = err.code.split('/')[1];
            const error = code.replace(new RegExp('-', 'g'), '_');
            ApplicationModule.setError({
                code: err.code,
                message: i18n.t(`firebase.error.${error}`).toString()
            });
            // this.SET_AUTHENTICATING(false);
        }
        ApplicationModule.setLoading(false);
    }

    @Action
    public async register(userInfo: {username: string; password: string}) {
        if (!ApplicationModule.online || this.authenticated) {
            return;
        }
        ApplicationModule.setLoading(true);
        ApplicationModule.clearError();
        const [err, userCredential] = await to(
            firebase.auth().createUserWithEmailAndPassword(userInfo.username, userInfo.password)
        );
        if (userCredential && userCredential.user) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'success',
                text: i18n.t('register.success').toString(),
                timeout: 5 * 1000
            });
        } else if (err) {
            const code: string = err.code.split('/')[1];
            const error = code.replace(new RegExp('-', 'g'), '_');
            ApplicationModule.setError({
                code: err.code,
                message: i18n.t(`firebase.error.${error}`).toString()
            });
        }
        ApplicationModule.setLoading(false);
    }

    @Action
    public async resetPassword(email: string) {
        if (!ApplicationModule.online || this.authenticated) {
            return;
        }
        ApplicationModule.setLoading(true);
        ApplicationModule.clearError();
        const [err] = await to(firebase.auth().sendPasswordResetEmail(email));
        if (err) {
            const code: string = err.code.split('/')[1];
            const error = code.replace(new RegExp('-', 'g'), '_');
            ApplicationModule.setError({
                code: err.code,
                message: i18n.t(`firebase.error.${error}`).toString()
            });
        } else {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'success',
                text: i18n.t('password.forgot.success').toString(),
                timeout: 5 * 1000
            });
        }
        ApplicationModule.setLoading(false);
    }

    @Action
    public async logout() {
        if (!ApplicationModule.online || !this.authenticated) {
            return;
        }
        ApplicationModule.setLoading(true);
        ApplicationModule.clearError();

        const [err] = await to(firebase.auth().signOut());

        if (err) {
            console.error(err);
        }

        ApplicationModule.showSnackbar({
            displayed: true,
            color: 'success',
            text: i18n.t('logout.success').toString(),
            timeout: 5 * 1000
        });

        ApplicationModule.setLoading(false);

        this.ResetToken();
        resetRouter();
        resetPermission();
    }

    @Action
    public async forceRefreshToken() {
        const user = firebase.auth().currentUser;
        if (user !== null) {
            this.validateLoginAsync(user, true);
        } else {
            this.ResetToken();
            resetRouter();
            resetPermission();
        }
    }

    @Action
    public ResetToken() {
        if (this.id) {
            eventBus.$emit('notify-server', JSON.stringify({ action: 'logout', userId: this.id }));
            myPyropDb.deleteUser(this.id);
        }
        this.SET_TOKEN(null);
        this.SET_ID(null);
        this.SET_LASTSYNCDATE(null);
        this.SET_FIRSTNAME(null);
        this.SET_LASTNAME(null);
        this.SET_COMPANY_POSITION(null);
        this.SET_EMAIL(null);
        this.SET_HOME_ADDRESS1(null);
        this.SET_HOME_ADDRESS2(null);
        this.SET_HOME_CITY(null);
        this.SET_HOME_ZIPCODE(null);
        this.SET_PICTURE_URL(null);
        this.SET_CLAIMS([]);
        this.SET_DEFAULTACTIVITY(null);
        this.SET_ISCLOCKINGONDEFAULTACTIVITY(false);
        this.SET_NOTIFICATIONS([]);
    }

    @Action
    public setAuthenticating(value: boolean) {
        this.SET_AUTHENTICATING(value);
    }

    @Action
    public setLastChosenTransportMode(value: PointageTransportMode) {
        this.SET_LASTCHOSENTRANSPORTMODE(value);
    }

    @Action
    public async initializeStore() {
        const [, users] = await to(myPyropDb.getUsers());

        if (users && Array.isArray(users) && users.length > 0) {
            let maxDate!: Date | undefined;
            let pIndex!: number | undefined;

            const valid = users.filter(usr => usr.hasAccess && usr.token && usr.lastSyncDate);
            valid.forEach((usr, index) => {
                const revivedDate = reviveUTCDate(usr.lastSyncDate as Date);

                if (!maxDate || maxDate < revivedDate) {
                    pIndex = index;
                }
            });
            if (pIndex !== undefined) {
                const user = valid[pIndex];
                this.SET_ANONYMOUS(user.anonymous);
                this.SET_EMAIL(user.email || null);
                this.SET_FIRSTNAME(user.firstName || null);
                this.SET_HASACCESS(user.hasAccess);
                this.SET_HASSS3ORSS4(user.hasSS3orSS4);
                this.SET_ID(user.id || null);
                this.SET_LASTNAME(user.lastName || null);
                this.SET_MEDICALVISITOK(user.medicalVisitOk);
                this.SET_LASTSYNCDATE(reviveUTCDate(user.lastSyncDate as Date));
                this.SET_LASTCHOSENTRANSPORTMODE(user.lastChosenTransportMode);
                this.SET_TOKEN(user.token as string);
                this.SET_CLAIMS(user.claims);
                this.SET_DEFAULTACTIVITY(user.defaultActivityId || null);
                this.SET_ISCLOCKINGONDEFAULTACTIVITY(user.isClockingOnDefaultActivity);
                this.SET_NOTIFICATIONS([]);
                store.commit('application/SET_SUBSCRIPTION', user.jsonSubscription);
            }
        }
    }

    @Action
    public serverUserDataUpdate(user: IUserData) {
        if (user.id) {
            myPyropDb.setUser({
                ...user,
                token: this.token || undefined,
                anonymous: this.anonymousLogin,
                jsonSubscription: ApplicationModule.jSonSubscription
            });
            eventBus.$emit('notify-server', JSON.stringify({ action: 'login', userId: user.id }));
        }
        this.SET_COMPANY_POSITION(user.companyPosition ?? null);
        this.SET_EMAIL(user.email ?? null);
        this.SET_FIRSTNAME(user.firstName ?? null);
        this.SET_HASACCESS(user.hasAccess);
        this.SET_HASSS3ORSS4(user.hasSS3orSS4);
        this.SET_HOME_ADDRESS1(user.homeAddressLine1 ?? null);
        this.SET_HOME_ADDRESS2(user.homeAddressLine2 ?? null);
        this.SET_HOME_CITY(user.homeCity ?? null);
        this.SET_HOME_ZIPCODE(user.homeZipCode ?? null);
        this.SET_PICTURE_URL(user.pictureUrl ?? null);
        this.SET_ID(user.id ?? null);
        this.SET_LASTCHOSENTRANSPORTMODE(user.lastChosenTransportMode);
        this.SET_LASTNAME(user.lastName ?? null);
        this.SET_MEDICALVISITOK(user.medicalVisitOk);
        this.SET_CLAIMS(user.claims);
        this.SET_DEFAULTACTIVITY(user.defaultActivityId || null);
        this.SET_ISCLOCKINGONDEFAULTACTIVITY(user.isClockingOnDefaultActivity);
        if (user.lastSyncDate) {
            this.SET_LASTSYNCDATE(reviveUTCDate(user.lastSyncDate));
        } else {
            this.SET_LASTSYNCDATE(null);
        }
    }

    @Action
    public addNotifications(values: Array<NotificationMessage>) {
        if (!values || !Array.isArray(values) || values.length === 0) {
            this.SET_NOTIFICATIONS([]);
            return;
        }
        this.SET_NOTIFICATIONS(values.map(notif => { return {...notif, createdDate: reviveUTCDate(notif.createdDate) }; }));
    }

    @Action
    public addNotification(value: NotificationMessage) {
        const notifs = cloneDeep(this.notifications);

        notifs.push({...value, createdDate: reviveUTCDate(value.createdDate)})

        this.SET_NOTIFICATIONS(notifs);
    }

    @Action
    public readNotification(value: NotificationMessage) {
        const notifs = cloneDeep(this.notifications);

        _.remove(notifs, no => no.id === value.id);

        this.SET_NOTIFICATIONS(notifs);
    }

    public get authenticated() {
        return !!this.token;
    }

    public get syncValid() {
        if (!this.lastSyncDate) {
            return false;
        }

        const now = new Date();
        return new Date(this.lastSyncDate.getTime() + 36 * 3600 * 1000) > now;
    }

    public get syncedHasAccess() {
        if (!this.id && this.lastName !== 'Anonyme') {
            return null;
        }
        return this.hasAccess;
    }

    public get defaultActivity() {
        if (!this.isClockingOnDefaultActivity) {
            return null;
        }
        return this.defaultActivityId;
    }
}

export const UserModule = getModule(MUser);
