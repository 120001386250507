import i18n from '@/i18n';
import {IMGEndpoint} from '@/main-config';
import {replacer} from '@/utils';
import {pluralize, pluralForm} from './pluralize';

const minutesToHour = (text: string, totalTime: number) => {
    const hours = Math.floor(totalTime / 60).toFixed(0);
    const minutes = totalTime % 60;
    return replacer(text, {hours, minutes});
};

export default {
    pluralize(text: string, amount: number, synthesize = true, digits = 0): string {
        return pluralize(text, amount, synthesize, digits, i18n.locale);
    },
    pluralizeFormatTime(text: string, amount: number): string {
        return minutesToHour(pluralForm(text, amount, i18n.locale), amount);
    },
    capitalize(value: string): string {
        if (!value) {
            return '';
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    imagizer(img: string, isAv: boolean, size: number): string {
        return (
            `${IMGEndpoint}/uploads/${isAv ? 'avatar/' : ''}` +
            `${img}?w=${size}&mode=crop&format=jpg&jpeg.progressive=true`
        );
    }
};
