/* eslint-disable no-console */

import {register} from 'register-service-worker';

import {ApplicationModule} from './store/modules/application';
import {urlB64ToUint8Array} from './utils';

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready(registration) {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );

            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                const messageChannel: MessageChannel = new MessageChannel();
                navigator.serviceWorker.controller.postMessage(
                    {
                        type: 'INIT_PORT'
                    },
                    [messageChannel.port2]
                );
                messageChannel.port1.onmessage = (ev: MessageEvent) => {
                    console.log('Broadcast received!', ev.data);
                    switch (ev.data.type) {
                        case 'SYNC_START':
                            ApplicationModule.setLoading(true);
                            break;
                        case 'SYNC_STOP':
                            ApplicationModule.setLoading(false);
                            break;
                        case 'SYNC_ERROR':
                            ApplicationModule.showSnackbar({
                                displayed: true,
                                color: 'error',
                                text:
                                    "La synchronisation n'a pas aboutie, un autre essai aura lieu ultérieurement.",
                                timeout: 5 * 1000
                            });
                            break;
                        case 'UPDATED':
                            ApplicationModule.setUpdated();
                            setTimeout(() => {
                                window.location.reload();
                            }, 10000);
                            break;
                        default:
                            break;
                    }
                };
            }
            // if (process.env.NODE_ENV === 'staging') {
            registration.pushManager.getSubscription().then(previousSub => {
                if (previousSub === null) {
                    registration.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlB64ToUint8Array(
                                'BNGXDjoH_FLrc6T3PosjEVXrRWwj0UIWW0ZBk7fWScSglJjPAzQFhCdgtB3JfZsHJ2SRFvsrvQfGwNj-umF-JPg'
                            )
                        })
                        .then(newSub => {
                            const subscriptionJson = newSub.toJSON();
                            ApplicationModule.updateSubscription(subscriptionJson);
                        })
                        .catch(() => {
                            ApplicationModule.showSnackbar({
                                displayed: true,
                                color: 'error',
                                text: 'Les notifications sont désactivées sur votre appareil.',
                                timeout: 5 * 1000
                            });
                        });
                } else {
                    const subscriptionJson = previousSub.toJSON();
                    ApplicationModule.updateSubscription(subscriptionJson);
                }
            });
            // }
        },
        registered() {
            console.log('Service worker has been registered.');
            // Routinely check for app updates by testing for a new service worker.
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');
            // if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            //     navigator.serviceWorker.controller.postMessage({type: 'SKIP_WAITING'});
            // }

            console.log('updated', registration);

            const newSW = registration.waiting;
            console.log('NEWSW=', newSW);

            if (newSW?.state === 'installed') {
                newSW.postMessage({type: 'SKIP_WAITING'});
            } else if (newSW) {
                newSW.onstatechange = () => {
                    console.log(`New State = ${newSW.state}`);
                    if (newSW.state === 'installed') {
                        newSW.postMessage({type: 'SKIP_WAITING'});
                    }
                };
            }

            // Add a custom event and dispatch it.
            // Used to display of a 'refresh' banner following a service worker update.
            // Set the event payload to the service worker registration object.
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
