import { GeolocationPositionView } from "../shared/GeolocationPosition";

/* eslint-disable-next-line no-shadow */
export enum PointageEventType {
    Start,
    Stop
}

export interface IEvenementPointageData {
    id: string;
    idPointage: string;
    browserName?: string;
    browserVersion?: string;
    cpuArchitecture?: string;
    dateHeureEvenement: Date;
    deviceModel?: string;
    deviceType?: string;
    deviceVendor?: string;
    engineName?: string;
    engineVersion?: string;
    ipAddress?: string;
    latitude: number;
    longitude: number;
    name: string;
    osName?: string;
    osVersion?: string;
    eventType: PointageEventType;
    userAgent?: string;
    version: number;
}

export type ClockInEventData = {
    id: string;
    browserName?: string;
    browserVersion?: string;
    cpuArchitecture?: string;
    deviceModel?: string;
    deviceType?: string;
    deviceVendor?: string;
    engineName?: string;
    engineVersion?: string;
    ipAddress?: string;
    latitude: number;
    longitude: number;
    position: GeolocationPositionView;
    name: string;
    osName?: string;
    osVersion?: string;
    eventType: PointageEventType;
    userAgent?: string;
}

export type IEvenementPointage = IEvenementPointageData;
