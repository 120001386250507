


































import {Vue, Component} from 'vue-property-decorator';

@Component
export default class HelpMenu extends Vue {
    public extended = false;
}
