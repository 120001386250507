


































































import {Vue, Component} from 'vue-property-decorator';
import SvgIcon from '@/components/SvgIcon.vue';
import {ExpositionModule} from '@/store/modules/exposition';

@Component({
    components: {SvgIcon}
})
export default class Step0Component extends Vue {
    private get tempsMaxRestant() {
        return 6 * 3600 - ExpositionModule.totalTimeToday;
    }

    private get canEnterWithMask() {
        return this.tempsMaxRestant > 0 && this.computedEnterWithMaskLeft > 0;
    }

    public get hasMask() {
        return ExpositionModule.ficheEnCours?.possedeMasque || false;
    }

    public set hasMask(value) {
        if (value && !this.canEnterWithMask) {
            return;
        }
        this.$emit('maskOptionChosen', value);
    }

    private get computedOuiColor() {
        return !this.canEnterWithMask ? 'grey' : this.$vuetify.theme.themes.light.success;
    }

    private get computedTimeLeft() {
        let timeLeft = this.tempsMaxRestant;
        if (timeLeft > 2.5 * 3600) {
            timeLeft = 2.5 * 3600;
        }

        const hours = Math.floor(timeLeft / 3600);
        const minuts = Math.floor((timeLeft - hours * 3600) / 60);
        const seconds = timeLeft - hours * 3600 - minuts * 60;

        return `${hours > 0 ? `${hours}h ` : ''}${minuts > 0 ? `${minuts}m et ` : ''}${seconds}s`;
    }

    private get computedEnterWithMaskLeft() {
        return 3 - ExpositionModule.amountFichesToday;
    }

    private get computedTimeLeftClass() {
        if (this.tempsMaxRestant > 1.5 * 3600) {
            return 'success--text';
        }
        if (this.tempsMaxRestant > 0) {
            return 'warning--text';
        }
        return 'error--text';
    }

    private get computedEnterWithMaskLeftClass() {
        if (ExpositionModule.amountFichesToday === 0) {
            return 'success--text';
        }
        if (ExpositionModule.amountFichesToday < 3) {
            return 'warning--text';
        }
        return 'error--text';
    }
}
